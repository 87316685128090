import React, { useEffect } from "react";
import "../../styles/Navbar.css";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import {
  buttonClick,
  closeClicks,
  buttonClicks,
  scrollFunction,
  carrerPage,
  contact,
  privacyPolicy,
  termsCondition,
  ourTeam,
  itemMain,
  itemMains,
  svgAnimation,
  svgAnimations,
} from "../../animations/navbarAnimation";

import { LogoSvg, OpenSvg, CloseSvg } from "../../svg/NavbarSvg";
import NavHamburger from "./NavHamburger";
import ScrollButton from "./ScrollButton";

function Navbar({ setLoading, inView, homePage }) {

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 550px)",
  });

  useEffect(() => {
    window.onscroll = function () {
      scrollFunction();
    };
    document.getElementById("navbar__div9").onclick = function () {
      isMobileDevice ? buttonClicks() : buttonClick();
    };
    document.getElementById("hamburger_close").onclick = function () {
      closeClicks();
    };
  }, [isMobileDevice]);

  function rollUp(){
    window.scrollTo(0,0);
    // scrollFunction();
    closeClicks();
    // setTimeout( ()=>{window.location.reload()}, 100);
  }

  return (
    <div className="navbar__div1">
      <div className="navbar__div2">
        <div className="navbar__div3">
          <motion.div
            onAnimationComplete={() => setLoading(false)}
            variants={isMobileDevice ? itemMains : itemMain}
            initial="hidden"
            animate="show"
            exit="exit"
            layoutId="main_video"
            className="navbar__div4"
            id="navbar__div4"
            style={
              inView
                ? { backgroundColor: "#222" }
                : { backgroundColor: "#f2d729" }
            }
          >
            <motion.div
              onAnimationComplete={() => setLoading(false)}
              variants={isMobileDevice ? svgAnimations : svgAnimation}
              initial="hidden"
              animate="show"
              exit="exit"
              className="navbar__div5"
              id="navbar__div5"
            >
              <Link to="/" onClick={rollUp}><LogoSvg inView={inView} /></Link>
            </motion.div>
            <NavHamburger
              inView={inView}
              homePage={homePage}
              carrerPage={carrerPage}
              contact={contact}
              privacyPolicy={privacyPolicy}
              termsCondition={termsCondition}
              ourTeam={ourTeam}
            />
          </motion.div>
          <div className="navbar__div6">
            <motion.div className="navbar__div7" id="navbar__div7">
              <div className="navbar__div8" id="navbar__div8">
                <p style={inView ? { color: "#222" } : { color: "#f2d729" }}>
                  Menu
                </p>
              </div>
              <div className="navbar__div80" id="navbar__div80">
                <p style={inView ? { color: "#f2d729" } : { color: "#222" }}>
                  Close
                </p>
              </div>
              <div>
                <div
                  id="navbar__div9"
                  // style={
                  //   inView
                  //     ? { border: "0.13vw solid #222" }
                  //     : { border: "0.13vw solid #f2d729" }
                  // }
                  style={
                    inView
                      ? { visibility: "hidden" }
                      : { visibility: "visible" }
                  }
                  className="navbar__div9"
                >
                  <OpenSvg inView={inView} />
                </div>
                <div
                  id="hamburger_close"
                  style={
                    inView
                      ? { border: "0.125vw solid #f2d729" }
                      : { border: "0.125vw solid #222" }
                  }
                  className="hamburger_close"
                >
                  <CloseSvg inView={inView} />
                </div>
              </div>
            </motion.div>
          </div>
          <ScrollButton inView={inView} />
        </div>
      </div>
    </div>
  );
}

export default Navbar;

import React from "react";
import { Link } from "react-router-dom";

function HomeLifeAtIgeeksSection() {
  return (
    <div className="home__div39">
      <div className="home__div40">
        <div className="home__div41"></div>
        <div className="home__div42">
          <p>Life</p>
          <p>at iGeeks</p>
        </div>
      </div>
      <div className="home__div43">
        <div className="home__div5">
          <div className="ui_Button">
          <Link to="/career/"> work with us</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeLifeAtIgeeksSection;

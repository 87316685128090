import React from "react";
import "../../styles/Slider.css";
import { Link } from "react-router-dom";

function Slider() {

  function scrollTop() {
    window.scrollTo(0,0);
  }

  return (
    <div className="Carousel">
      <section className="slideshow">
        <div className="slide">
          <div className="slider">
            <div className="slider_content" id="slider_1">
              <h1>Tech Writer</h1>
              <p>
              iGeeks is looking for a tech writer who can work with teams handling different projects, across the organization, to write engaging content for humans and not just search engines.
              </p>
              <Link to="/content-writer/" onClick={scrollTop}>View Job</Link>
            </div>
          </div>
          <div className="slider">
            <div className="slider_content" id="slider_2">
              <h1>SEO Executive</h1>
              <p>
              We are looking for an SEO Executive to join our Marketing team and help us build a strong online presence.To be successful as an SEO Executive, you should be passionate about technology and digital communications.
              </p>
              <Link to="/search-engine-optimization/" onClick={scrollTop}>View Job</Link>
            </div>
          </div>
          <div className="slider">
            <div className="slider_content" id="slider_3">
              <h1>Content Creator</h1>
              <p>
              iGeeks is looking for a content creator who can work with teams handling different projects, across the organization, to write engaging content for humans and not just search engines.
              </p>
              <Link to="/content-creator/" onClick={scrollTop}>View Job</Link>
            </div>
          </div>
          <div className="slider">
            <div className="slider_content" id="slider_4">
              <h1>Copywriter</h1>
              <p>
              We are looking for a creative Copywriter to write clear and concise copy for ads, publications and websites. Your words will inform and engage target audiences.
Our ideal candidate is a team-spirited, skilled and imaginative writer with an eye for detail. If you can grasp project requirements quickly and offer valuable insight, we’d like to meet you.
              </p>
              <Link to="/copywriter/" onClick={scrollTop}>View Job</Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Slider;

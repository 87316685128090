import React from "react";
import { Link } from "react-router-dom";

function NavHamburger({
  inView,
  // homePage,
  carrerPage,
  contact,
  privacyPolicy,
  termsCondition,
  ourTeam,
}) {
  return (
    <div
      className="ham__div1"
      id="ham__div1"
      style={inView ? { color: "#fff" } : { color: "#222" }}
    >
      <div className="ham__div2">
        <div className="ham__div3">
          <div className="ham__extra1">
            <p><a href="mailto:info@igeeksmedia.com" target="_blank" rel="noreferrer">info@igeeksmedia.com</a></p>
          </div>
          <br />
          <Link
            to="/privacy/"
            style={inView ? { color: "#fff" } : { color: "#222" }}
          >
            <p id="homepage" onClick={() => privacyPolicy()}>
              Privacy Policy
            </p>
          </Link>
          <Link
            to="/disclaimer/"
            style={inView ? { color: "#fff" } : { color: "#222" }}
          >
            <p id="homepage" onClick={() => termsCondition()}>
              Terms and Conditions
            </p>
          </Link>
        </div>
        <div className="ham__div4">
          <div className="ham__div5">
            <Link
              to="/our-team/"
              style={inView ? { color: "#fff" } : { color: "#222" }}
            >
              <p id="homepage" onClick={() => ourTeam()}>
                Our Team
              </p>
            </Link>
          </div>
          <div className="ham__div5">
            <Link
              to="/career/"
              style={inView ? { color: "#fff" } : { color: "#222" }}
            >
              <p id="homepage" onClick={() => carrerPage()}>
                Careers
              </p>
            </Link>
          </div>
          <div className="ham__div5">
            <Link
              to="/contact/"
              style={inView ? { color: "#fff" } : { color: "#222" }}
            >
              <p id="homepage" onClick={() => contact()}>
                Contact
              </p>
            </Link>
          </div>
          <div className="ham__div5">
            <a href="https://www.igeeksblog.com/advertise/" target="_blank" rel="noreferrer" style={inView ? { color: "#fff" } : { color: "#222" }}>
              <p>
                Advertise with us
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavHamburger;

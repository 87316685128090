import React from "react";
import Slider from "../components/slider/Slider";
import { Link } from "react-router-dom";
import "../styles/Home.css";
import Marquee from "react-fast-marquee";
// import Kawaii from "./Kawaii";
import Kawaii from '../components/blob/Kawaii'
import altz1 from "../images-webp/altz1.webp";
import altz2 from "../images-webp/altz2.webp";
import altz3 from "../images-webp/altz3.webp";
import altz4 from "../images-webp/altz4.webp";
import altz5 from "../images-webp/altz5.webp";
import altz6 from "../images-webp/altz6.webp";
import altznew2 from "../images-webp/altznew2.webp";
import altznew3 from "../images-webp/altznew3.webp";
import altznew4 from "../images-webp/altznew4.webp";
import altznew5 from "../images-webp/altznew5.webp";
import altznew6 from "../images-webp/altznew6.webp";
// import { motion } from "framer-motion";
import { ourTeam } from "../animations/navbarAnimation";

import {
  onMouseEnetrs,
  onMouseEnetrss,
  onMouseEnetrsss,
  onMouseLeave,
  onMouseLeaves,
  onMouseLeavess,
} from "../animations/homeAnimation";

// React Helmet
import { Helmet, HelmetProvider } from 'react-helmet-async';

// import { ouTeam } from "../animations/navbarAnimation"


// Social Icons

// AOS

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import OurStory from "../components/ourStory/OurStory"
// import { HomeStarSvg } from "../svg/HomeSvg";

import HomeApprochSection from "../components/home/HomeApprochSection";
// import HomeThreeMarqueSection from "../components/home/HomeThreeMarqueSection";
import HomeAboutUsSection from "../components/home/HomeAboutUsSection";
import HomeLifeAtIgeeksSection from "../components/home/HomeLifeAtIgeeksSection";
import HomeFlowCards from "../components/home/HomeFlowCards";
import HomeIgeeksBlogsection from "../components/home/HomeIgeeksBlogsection";
import HomeOurFoundation from "../components/home/HomeOurFoundation";
import HomeBrandCollaboration from "../components/home/HomeBrandCollaboration";
import HomeInternationalAccol from "../components/home/HomeInternationalAccol";
import HomeThreeBoxes from "../components/home/HomeThreeBoxes";
// ..
AOS.init();

AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
  initClassName: "aos-init", // class applied after initialization
  animatedClassName: "aos-animate", // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 0, // offset (in px) from the original trigger point
  delay: 200, // values from 0 to 3000, with step 50ms
  duration: 1000, // values from 0 to 3000, with step 50ms
  easing: "ease", // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
});

function Home({ contentRef, inView }) {
  return (
    <HelmetProvider>
    <div className="home__div1" id="home__div1">
      <Helmet>
      {/* <!-- Primary Meta Tags --> */}
<title>Most Trusted Tech Media Company | iGeeks Media Pvt. Ltd.</title>
<meta name="title" content="Most Trusted Tech Media Company | iGeeks Media Pvt. Ltd." />
<meta name="description" content="iGeeks Media Pvt Ltd is India's most trusted tech media company. We empower individuals to make the most of today's ever-evolving technologies." />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.igeeksmedia.com/" />
<meta property="og:title" content="Most Trusted Tech Media Company | iGeeks Media Pvt. Ltd." />
<meta property="og:description" content="iGeeks Media Pvt Ltd is India's most trusted tech media company. We empower individuals to make the most of today's ever-evolving technologies." />
<meta property="og:image" content="" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.igeeksmedia.com/" />
<meta property="twitter:title" content="Most Trusted Tech Media Company | iGeeks Media Pvt. Ltd." />
<meta property="twitter:description" content="iGeeks Media Pvt Ltd is India's most trusted tech media company. We empower individuals to make the most of today's ever-evolving technologies." />
<meta property="twitter:image" content="" />
      </Helmet>
      <div className="home__div2">
        <div className="home__div3">
          <div className="home__div4">
            <div className="home__div000">
              <Kawaii />
            </div>
            <div className="home__div5">
                {/* <div className="ui_Button">
                <Link to="/contact">get in touch</Link>
                </div> */}
            </div>
            <div className="home__div8">
              <p>
                <span>A Premier Source </span>
                <span>of Information:</span> <span>50M+ Global Tech</span>{" "}
                <span>Consumers</span>
                <span> Every Year </span>
              </p>
            </div>
          </div>
          <div className="home__div9">
            <div className="home__div10">
              <Marquee className="home__marquee" speed={200} gradient={false}>
                <p>Do more with Tech</p>
              </Marquee>
            </div>
          </div>
          <div className="home__div11">
            <HomeApprochSection />
          </div>
          <div className="home__div15">
            {/* <div className="home__divEExtra">
              <HomeThreeMarqueSection />
            </div> */}
            <HomeThreeBoxes />
            <div className="home__div28">
              <HomeAboutUsSection />
            </div>
          </div>
          <div className="home__div38">
            <HomeLifeAtIgeeksSection />
          </div>
          <div className="home__div37">
            <Marquee className="home__mar" speed={70} gradient={false}>
              <img src={altz1} className="home__img10" alt="" />
              <img src={altznew2} className="home__img10" alt="" />
              <img src={altz2} className="home__img10" alt="" />
              <img src={altznew3} className="home__img10" alt="" />
              <img src={altz3} className="home__img10" alt="" />
              <img src={altznew4} className="home__img10" alt="" />
              <img src={altz4} className="home__img10" alt="" />
              <img src={altznew5} className="home__img10" alt="" />
              <img src={altz5} className="home__img10" alt="" />
              <img src={altznew6} className="home__img10" alt="" />
              <img src={altz6} className="home__img10" alt="" />
            </Marquee>
          </div>
          <div className="divider">
            <div className="home__div38">
              <div className="home__div39">
                <div className="home__div40">
                  <div className="home__div41"></div>
                  <div className="home__div42">
                    <p>Verticles</p>
                    <p>We Work In</p>
                  </div>
                </div>
                <div className="home__div43"></div>
              </div>
            </div>
          </div>
          <div className="home__div44">
            <HomeFlowCards
              onMouseEnetrs={onMouseEnetrs}
              onMouseEnetrss={onMouseEnetrss}
              onMouseEnetrsss={onMouseEnetrsss}
              onMouseLeave={onMouseLeave}
              onMouseLeaves={onMouseLeaves}
              onMouseLeavess={onMouseLeavess}
            />
          </div>
          <div className="home__div20000">
            <HomeIgeeksBlogsection />
          </div>
          <div className="home__div51">
            <HomeOurFoundation inView={inView} ourTeam={ourTeam}/>
          </div>
        </div>
        <div
          className="ourStory__div1"
          id="ourStory__div1"
          ref={contentRef}
        >
          <OurStory inView={inView} />
        </div>

        <div style={{ backgroundColor: "transparent" }} className="home__div60">
          <HomeBrandCollaboration />
        </div>
        <div className="home__div60">
          <HomeInternationalAccol />
        </div>
        <div className="home__div38">
          <div className="home__div39">
            <div className="home__div40">
              <div className="home__div41"></div>
              <div className="home__div42">
                <p>Let's Hustle</p>
                <p>Together</p>
              </div>
            </div>
            <div className="home__div43">
            <div className="home__div5">
            <div className="ui_Button">
              <Link to="/career/">work with us</Link>
            </div>
            </div>
            </div>
          </div>
        </div>
        <Slider />
      </div>
    </div>
    </HelmetProvider>
  );
}
export default Home;

import React from "react";
import Kawaii from "../components/blob/Kawaii";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Error() {
  return (
    <HelmetProvider>
      <Helmet>
          {/* <!-- Primary Meta Tags --> */}
          <title>Error - iGeeks Media</title>
          <meta name="title" content="Error - iGeeks Media" />
          <meta name="description" content="Error Page - 404 Not Found" />
      </Helmet>
    <div className="">
    <div className="wrapper">
      <div>
        <div className="error_page">
          <div className="home__div4">
            <div className="home__div000">
              <Kawaii />
            </div>
            <div className="home__div5"></div>
            <div className="carrer__div8000 eror">
              <p>
                <span>404</span>
                <span> Error</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="redirect">
        <h1>Go back to <Link onClick={()=>{window.scrollTo(0,0)}} to="/">Homepage</Link></h1>
      </div>
    </div>
    </div>
    </HelmetProvider>
  );
}

export default Error;

import { useEffect, useState } from "react";
import "./App.css";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home";
import Navbar from "./components/navbar/Navbar";
import { Route, Switch } from "react-router-dom";
import Career from "./pages/Carrer";
import { AnimatePresence } from "framer-motion";
import Policies from "./pages/Policies";
import Terms from "./pages/Terms";
import Contact from "./pages/Contact";
import StaffContent from "./pages/StaffContent";
import Marketer from "./pages/Marketer";
import Graphic from "./pages/Graphic";
import VideoE from "./pages/VideoE";
import SocialMedia from "./pages/SocialMedia";
import OurTeam from "./pages/OurTeam";
import Seo from "./pages/Seo";
import { useInView } from "react-intersection-observer";
import { scrollFunction } from "./animations/navbarAnimation"
import { closeClicks } from "./animations/navbarAnimation"
import Copywriter from "./pages/Copywriter";
import Content from "./pages/Content";
import Error from "./pages/Error";
// "postbuild": "react-snap",

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loading
      ? document.querySelector("body").classList.add("loading")
      : document.querySelector("body").classList.remove("loading");
  }, [loading]);

  const [contentRef, inView] = useInView({
    triggerOnce: false,
    rootMargin: "-150px",
  });

  useEffect(() => {
    if (inView) {
      document.body.style.backgroundColor = "#f2d729";
    } else {
      document.body.style.backgroundColor = "#222";
    }
  }, [inView]);

  window.onbeforeunload = function () {
    window.scrollTo(0,0);
    document.getElementById("whole_container").style.opacity = 0;
  };  

  window.addEventListener("resize", ()=>{
    // scrollFunction();
    closeClicks();
  })

  return (
    <AnimatePresence initial={true} exitBeforeEnter>
      <div id="app" className="app">
        <Navbar setLoading={setLoading} inView={inView} />
        <div id="whole_container">
          <Switch>
        <Route path="/" exact>
          <Home inView={inView} contentRef={contentRef} />
        </Route>

        <Route path="/career" exact>
          <Career />
        </Route>
        <Route path="/content-writer" exact>
          <StaffContent />
        </Route>
        <Route path="/content-marketer" exact>
          <Marketer />
        </Route>
        <Route path="/graphic-designer" exact>
          <Graphic />
        </Route>
        <Route path="/video-editor" exact>
          <VideoE />
        </Route>
        <Route path="/social-media" exact>
          <SocialMedia />
        </Route>
        <Route path="/copywriter" exact>
          <Copywriter />
        </Route>
        <Route path="/content-creator" exact>
          <Content />
        </Route>
        <Route path="/search-engine-optimization" exact>
          <Seo />
        </Route>
        <Route path="/privacy" exact>
          <Policies />
        </Route>
        <Route path="/disclaimer" exact>
          <Terms />
        </Route>
        <Route path="/contact" exact>
          <Contact />
        </Route>
        <Route path="/our-team" exact>
          <OurTeam />
        </Route>
        <Route path="*" exact>
          <Error />
        </Route>
        </Switch>
        <Footer />
        </div>
      </div>
    </AnimatePresence>
  );
}

export default App;

import React from "react";
import { hydrate, render } from "react-dom";
// import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";

const rootElement = document.getElementById("root");
// const root = document.getElementById("root")

if (rootElement.hasChildNodes()){
  hydrate(
      <Router>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Router>,
      rootElement
  );
}else{
  render(
      <Router>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Router>,
      rootElement
  );
}


reportWebVitals();

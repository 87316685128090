import React from "react";
import { motion } from "framer-motion";
import LanguageIcon from '@mui/icons-material/Language';
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import AppleIcon from "@mui/icons-material/Apple";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TelegramIcon from '@mui/icons-material/Telegram';
// import GoogleIcon from "@mui/icons-material/Google";
import PodcastsIcon from "@mui/icons-material/Podcasts";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

function HomeFlowCards({
  onMouseEnetrs,
  onMouseLeave,
  onMouseLeaves,
  onMouseEnetrss,
  onMouseEnetrsss,
  onMouseLeavess,
}) {
  return (
    <>
    <div className="home__div45">
      <motion.div
      className="home__div46"
      id="home__div4600"
      onMouseEnter={() => onMouseEnetrsss()}
      onMouseLeave={() => onMouseLeavess()}
      >
        <div className="home__cardsExtra">
        <div className="home__div47" id="home__div4700">
            <p>iGeeksBlog</p>
          </div>
          <div className="home__div48">
            <div className="home__div49">
              {/* <p>Sneak Out of Products</p> */}
              <div className="home__div500">
              <a href="https://www.igeeksblog.com/" target="_blank" rel="noreferrer" className="colorY">
              <span><LanguageIcon /></span>
                  <p> iGeeksBlog</p>
                </a>
                <a
                  href="https://apps.apple.com/in/app/igeeksblog-tech-news-tips/id1567408764"
                  target="_blank" rel="noreferrer"
                  className="colorY"
                >
                  <AppleIcon />
                  <p> iOS App</p>
                </a>
                <a
                  href="https://www.linkedin.com/company/igeeksblog/"
                  target="_blank" rel="noreferrer"
                >
                  <LinkedInIcon />
                  <p> LinkedIn</p>
                </a>
                {/* <a href="https://twitter.com/igeeksblog" target="_blank" rel="noreferrer">
                  <TwitterIcon />
                  <p> Twitter</p>
                </a> */}
                {/* <a href="https://www.facebook.com/igeeksblog" target="_blank" rel="noreferrer">
                  <FacebookIcon />
                  <p> Facebook</p>
                </a> */}
              </div>
              <div className="home__div500">
              {/* <a
                  href="https://www.linkedin.com/company/igeeksblog/"
                  target="_blank" rel="noreferrer"
                >
                  <LinkedInIcon />
                  <p> LinkedIn</p>
                </a> */}
                {/* <a href="https://www.instagram.com/igeeksblog/" target="_blank" rel="noreferrer">
                  <InstagramIcon /> <p>Instagram</p>
                </a> */}
                {/* <a href="https://pinterest.com/igeeksblog/" target="_blank" rel="noreferrer">
                  <PinterestIcon />
                  <p> Pinterest</p>
                </a> */}
                {/* <a
                  href="https://theigeeksblogshow.buzzsprout.com/"
                  target="_blank" rel="noreferrer"
                >
                  <PodcastsIcon />
                  <p>Podcasts</p>
                </a> */}
                {/* <a
                  href="https://open.spotify.com/show/3New9eYmam7ncNCy2gYNqm"
                  target="_blank"
                >
                  <span>
                    <strong>Sp</strong>
                  </span>
                  <p>Spotify</p>
                </a> */}
                {/* <a
                  href="https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80NzA3MDVlOC9wb2RjYXN0L3Jzcw=="
                  target="_blank"
                >
                  <GoogleIcon /> <p>Google Podcast</p>
                </a> */}
              </div>
            </div>
            <div className="home__div50">
              <p>
                Being a reliable tech information platform, we have established
                our footprints on all imaginable social networks
              </p>
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div
        className="home__div46"
        id="home__div46"
        onMouseEnter={() => onMouseEnetrs()}
        onMouseLeave={() => onMouseLeave()}
      >
        <div className="home__cardsExtra">
        <div className="home__div47" id="home__div47">
            <p>Visual Content</p>
          </div>
          <div className="home__div48">
            <div className="home__div49">
              {/* <p>Sneak Out of Products</p> */}
              <div className="home__div500">
              <a
                  href="https://www.youtube.com/@iGeeksBlog"
                  target="_blank" rel="noreferrer"
                >
                  <YouTubeIcon />
                  <p> Youtube</p>
                </a>
                <a href="https://www.instagram.com/igeeksblog/" target="_blank" rel="noreferrer">
                  <InstagramIcon /> <p>Instagram</p>
                </a>
                <a href="https://www.facebook.com/iGeeksBlog" target="_blank" rel="noreferrer">
                  <FacebookIcon /> <p>Facebook</p>
                </a>
                <a href="https://open.spotify.com/show/7KQpgAifnGmC8kOM1SpVJq?si=4a7aee2760f0438b" target="_blank" rel="noreferrer">
                  <PodcastsIcon />
                  <p> Podcast</p>
                </a>
              </div>
              <div className="home__div500">
                <a href="https://in.pinterest.com/igeeksblog/" target="_blank" rel="noopener noreferrer">
                  <PinterestIcon />
                  <p> Pinterest</p>
                </a>
                <a href="https://twitter.com/igeeksblog" target="_blank" rel="noopener noreferrer">
                  <TwitterIcon />
                  <p> Twitter</p>
                </a>
                <a href="https://t.me/igeeks_blog" target="_blank" rel="noopener noreferrer">
                  <TelegramIcon />
                  <p> Telegram</p>
                </a>
              </div>
            </div>
            <div className="home__div50" id="home__div50">
              <p>
                Our extensive digital presence on the channels demonstrates that
                we remain in touch with their dedicated tech readers. We connect
                with people all over the world and are available to speak with
                you digitally wherever you are.
              </p>
            </div>
          </div>
        </div>
      </motion.div>
      <motion.div
        className="home__div46"
        id="home__div460"
        onMouseEnter={() => onMouseEnetrss()}
        onMouseLeave={() => onMouseLeaves()}
      >
        <div className="home__cardsExtra">
          <div className="home__div47" id="home__div470">
            <p>Geekified</p>
          </div>
          <div className="home__div48">
            <div className="home__div49">
              {/* <p>Sneak Out of Products</p> */}
              <div className="home__div500"> <a href="https://www.igeekified.com/" target="_blank" rel="noreferrer" className="colorY">
              <span><LanguageIcon /></span>
                  <p> iGeekified</p>
                </a>

                <a
                  href="https://www.youtube.com/@igeekified"
                  target="_blank" rel="noreferrer"
                >
                  <YouTubeIcon /> <p> Youtube</p>
                </a>
                <a
                  href="https://www.instagram.com/igeekified/"
                  target="_blank" rel="noreferrer"
                >
                  <InstagramIcon /> <p> Instagram</p>
                </a>
                <a
                  href="https://twitter.com/igeekified"
                  target="_blank" rel="noreferrer"
                >
                  <TwitterIcon /> <p> Twitter</p>
                </a>
                <a
                  href="https://open.spotify.com/show/3exvIlOS5unfMyjDOX6cLd?si=b5c16eb602114825"
                  target="_blank" rel="noreferrer"
                >
                  <PodcastsIcon /> <p> Podcast</p>
                </a>
                <a
                  href="https://t.me/geekified"
                  target="_blank" rel="noreferrer"
                >
                  <TelegramIcon /> <p> Telegram</p>
                </a>
              </div>
            </div>
            <div className="home__div50" id="home__div51">
              <p>
              Treasure troves are rare to find, so we created one! Welcome to Geekified, a place where we bring you close to Technology, Gadgets, and Games. Our creators will guide you through the nooks and corners of complex tech jargon, complicated but useful gadgets, and must-try online games. Follow the channel to learn how you can Tech it easy!
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
    <div className="res_div45">
      <div className="card" id="card1" data-aos="fade-up">
            <h1>iGeeksBlog</h1>
            <p>
            Being a reliable tech information platform, we have established
                our footprints on all imaginable social networks.
            </p>
            <div className="social_icons">
            <a href="https://www.igeeksblog.com/" target="_blank" rel="noreferrer" className="colorY">
              <span><LanguageIcon /></span>
                  <p> iGeeksBlog</p>
                </a>
                <a
                  href="https://apps.apple.com/in/app/igeeksblog-tech-news-tips/id1567408764"
                  target="_blank" rel="noreferrer"
                  className="colorY"
                >
                  <AppleIcon />
                  <p> iOS App</p>
                </a>
                <a
                  href="https://www.linkedin.com/company/igeeksblog/"
                  target="_blank" rel="noreferrer"
                >
                  <LinkedInIcon />
                  <p> LinkedIn</p>
                </a>
            </div>
      </div>
      <div className="card" id="card2" data-aos="fade-up">
            <h1>Visual Content</h1>
            <p>
            Our extensive digital presence on the channels demonstrates that
                we remain in touch with their dedicated tech readers. We connect
                with people all over the world and are available to speak with
                you digitally wherever you are.
            </p>
            <div className="socialContainer" style={{display: "flex", gap: "4vw"}}>
            <div className="social_icons">
            <a
                  href="https://www.youtube.com/@iGeeksBlog"
                  target="_blank" rel="noreferrer"
                >
                  <YouTubeIcon />
                  <p> Youtube</p>
                </a>
                <a href="https://www.instagram.com/igeeksblog/" target="_blank" rel="noreferrer">
                  <InstagramIcon /> <p>Instagram</p>
                </a>
                <a href="https://www.facebook.com/iGeeksBlog" target="_blank" rel="noreferrer">
                  <FacebookIcon /> <p>Facebook</p>
                </a>
                <a href="https://open.spotify.com/show/7KQpgAifnGmC8kOM1SpVJq?si=4a7aee2760f0438b" target="_blank" rel="noreferrer">
                  <PodcastsIcon />
                  <p> Podcast</p>
                </a>
            </div>
            <div className="social_icons">
            <a href="https://in.pinterest.com/igeeksblog/" target="_blank" rel="noopener noreferrer">
                  <PinterestIcon />
                  <p> Pinterest</p>
                </a>
                <a href="https://twitter.com/igeeksblog" target="_blank" rel="noopener noreferrer">
                  <TwitterIcon />
                  <p> Twitter</p>
                </a>
                <a href="https://t.me/igeeks_blog" target="_blank" rel="noopener noreferrer">
                  <TelegramIcon />
                  <p> Telegram</p>
                </a>
            </div>
            </div>
      </div>
      <div className="card" id="card3" data-aos="fade-up">
            <h1>Geekified</h1>
            <p>
            Treasure troves are rare to find, so we created one! Welcome to Geekified, a place where we bring you close to Technology, Gadgets, and Games. Our creators will guide you through the nooks and corners of complex tech jargon, complicated but useful gadgets, and must-try online games. Follow the channel to learn how you can Tech it easy!
            </p>
            <div className="socialContainer" style={{display: "flex", gap: "4vw"}}>
            <div className="social_icons">
            <a
                  href="https://www.youtube.com/@igeekified"
                  target="_blank" rel="noreferrer"
                >
                  <YouTubeIcon /> <p> Youtube</p>
                </a>
                <a
                  href="https://www.instagram.com/igeekified/"
                  target="_blank" rel="noreferrer"
                >
                  <InstagramIcon /> <p> Instagram</p>
                </a>
                <a
                  href="https://twitter.com/igeekified"
                  target="_blank" rel="noreferrer"
                >
                  <TwitterIcon /> <p> Twitter</p>
                </a>
            </div>
            <div className="social_icons">
            <a
                  href="https://open.spotify.com/show/3exvIlOS5unfMyjDOX6cLd?si=b5c16eb602114825"
                  target="_blank" rel="noreferrer"
                >
                  <PodcastsIcon /> <p> Podcast</p>
                </a>
                <a
                  href="https://t.me/geekified"
                  target="_blank" rel="noreferrer"
                >
                  <TelegramIcon /> <p> Telegram</p>
                </a>
            </div>
            </div>
      </div>
    </div>
    </>
  );
}

export default HomeFlowCards;

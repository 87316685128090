import React, { useLayoutEffect } from "react";
import "../styles/Policies.css";
import Kawaii from "../components/blob/Kawaii";
import { motion } from "framer-motion";
// React Helmet
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Policies() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <HelmetProvider>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: "0.5s all ease" }}
      exit={{ opacity: 0 }}
      className="policy__div1"
    >
      <Helmet>
      {/* <!-- Primary Meta Tags --> */}
<title>Privacy Policy | iGeeks Media Pvt Ltd</title>
<meta name="title" content="Privacy Policy | iGeeks Media Pvt Ltd" />
<meta name="description" content="We at iGeeks Media Pvt. Ltd. prioritize the privacy of all
our users and are committed to protecting their personal information." />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.igeeksmedia.com/privacy/" />
<meta property="og:title" content="Privacy Policy | iGeeks Media Pvt Ltd" />
<meta property="og:description" content="We at iGeeks Media Pvt. Ltd. prioritize the privacy of all
our users and are committed to protecting their personal information." />
<meta property="og:image" content="" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.igeeksmedia.com/privacy/" />
<meta property="twitter:title" content="Privacy Policy | iGeeks Media Pvt Ltd" />
<meta property="twitter:description" content="We at iGeeks Media Pvt. Ltd. prioritize the privacy of all
our users and are committed to protecting their personal information." />
<meta property="twitter:image" content="" />
      </Helmet>
      <div className="policy__div2">
        <div className="home__div4">
          <div className="home__div000">
            <Kawaii />
          </div>
          <div className="home__div5"></div>
          <div className="home__div8 policy_title">
            <p>
              <span>Privacy </span>
              <span>Policies</span>
            </p>
          </div>
        </div>
      </div>
      <div className="policy_container">
        <h1>
          The privacy of the visitors to iGeeks Media website is important to
          us.
        </h1>
        <br />
        <br />
        <p>
          At iGeeks Media, we recognize that privacy of your personal
          information is important. Here is information on what types of
          personal information we receive and collect when you visit iGeeks
          Media, and how we safeguard your information. We will never sell your
          personal information, including your email address, to third parties.
        </p>
        <br />
        <br />
        <h3>Log Files</h3>
        <br />
        <br />
        <p>
          As with most other websites, we collect and use the data contained in
          log files. The information inside the log files includes internet
          protocol (IP) addresses, type of browser, Internet Service Provider
          (ISP), date/time stamp, referring/exit pages, and a number of clicks
          to analyze trends, administer the site, track user’s movement around
          the site, and gather demographic information. IP addresses and other
          such information are not linked to any information that is personally
          identifiable.
        </p>
        <br />
        <br />
        <h3>Cookies and Web Beacons</h3>
        <br />
        <br />
        <p>
          iGeeks Media does use cookies to store information about visitors
          preferences, record user-specific information on which pages the user
          access or visit, customize Web page content based on visitors browser
          type or other information that the visitor sends via their browser.
        </p>
        <br />
        <br />
        <h3>DoubleClick DART Cookie</h3>
        <br />
        <br />
        <p>
          - Google, as a third-party vendor, uses cookies to serve ads on iGeeks
          Media - Google's use of the DART cookie enables it to serve ads to
          users based on their visit to iGeeks Media and other sites on the
          Internet. - Users may opt out of the use of the DART cookie by
          visiting the Google ad and content network privacy policy at the
          following URL - http://www.google.com/privacy_ads.html
        </p>
        <br />
        <p>
          Some of our advertising partners may use cookies and web beacons on
          our site. Our advertising partners include… Google Adsense
        </p>
        <br />
        <p>
          These third-party ad servers or ad networks use technology to the
          advertisements and links that appear on iGeeks Media send directly to
          your browsers. They automatically receive your IP address when this
          occurs. Other technologies (such as cookies, JavaScript, or Web
          Beacons) may also be used by the third-party ad networks to measure
          the effectiveness of their advertisements and/or to personalize the
          advertising content that you see.
        </p>
        <br />
        <p>
          iGeeks Media has no access to or control over these cookies that are
          used by third-party advertisers.
        </p>
        <br />
        <p>
          You should consult the respective privacy policies of these
          third-party ad servers for more detailed information on their
          practices as well as for instructions about how to opt-out of certain
          practices. iGeeks Media’s privacy policy does not apply to, and we
          cannot control the activities of, such other advertisers or web sites.
        </p>
        <br />
        <p>
          If you wish to disable cookies, you may do so through your individual
          browser options. More detailed information about cookie management
          with specific web browsers can be found at the browsers’ respective
          websites.
        </p>
        <br />
        <br />
        <h3>Google Analytics Cookie</h3>
        <br />
        <br />
        <p>
          We use Google Analytics to get insights into consumer demography,
          psychography/consumer behavior, traffic sources, and other necessary
          information. However, we do not share the same for any commercial
          purpose.
        </p>
        <br />
        <br />
        <h3>Content Policy</h3>
        <br />
        <br />
        <p>
          - All the articles/contents published on iGeeks Media have been
          uniquely written by respected authors. Based on information found on
          the internet from various sources like material, news, videos, etc.,
          articles are written after thorough research. In some cases, the
          information mentioned in the contents may vary as they may have been
          published a long time back. Hence, we request you to drop an email at
          info@igeeksmedia.com to change/update the content. - We reserved right
          to change the content, delete the content, delete the pages and
          information on this website anytime. - We believe the content
          presented here cannot be 100% accurate as well correct. It could vary
          from another source of information.
        </p>
        <br />
        <br />
        <h3>Image Policy</h3>
        <br />
        <br />
        <p>
          - The images, shown in the article/content, contain the ownership of
          iGeeks Media. The images, which are from third-party, are given credit
          passes to individual ownership. Images are used merely for the
          reference purpose. - If you have the ownership of the Images and do
          not want to let us use them; kindly email us at info@igeeksmedia.com
          to remove the same.
        </p>
        <br />
        <p>
          We care about the personal information you share online. At some
          points, iGeeks Media collects personal information so please read the
          policies to make sure you understand our practices fully.
        </p>
        <br />
        <br />
        <h3>Comment</h3>
        <br />
        <br />
        <p>
          Email addresses are required for commenting and they are not published
          along with a comment. We do not share them with any third-party
          commenting tools for any commercial use.
        </p>
        <br />
        <br />
        <h3>Newsletter Subscription</h3>
        <br />
        <br />
        <p>
          Email addresses are required to join our newsletter subscription. Only
          verified email addresses with your prior consent will receive future
          updates from iGeeks Media.
        </p>
        <br />
        <br />
        <h3>Fill-Up Forms</h3>
        <br />
        <br />
        <p>
          Name and Email address are required to contact iGeeks Media for any
          queries or business inquiries. We use that personal information to
          contact you back for your query or business inquiries. We do not use
          this information for any other promotional activities or share with
          other third-party tools.
        </p>
        <br />
        <br />
        <h3>Personal Information of Contest Participant</h3>
        <br />
        <br />
        <p>
          We may ask for Name and Email address of contest participant. We may
          also ask for a contact number, residential address or other required
          personal information from contest winner to contact him/her and to
          deliver the prize.
        </p>
        <br />
        <p>
          Along with that iGeeks Media is created for information purpose only.
          The information we have posted here is a best with our knowledge and
          collected from the web. The information might be wrong or out dated
          and Visitor will only responsible for his/her own decision.
        </p>
      </div>
    </motion.div>
    </HelmetProvider>
  );
}

export default Policies;

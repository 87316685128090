import React from "react";
import "../styles/Policies.css";
import Kawaii from "../components/blob/Kawaii";
// React Helmet
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Terms() {
  return (
    <HelmetProvider>
    <div className="policy__div1">
      <Helmet>
      {/* <!-- Primary Meta Tags --> */}
<title>Terms & Conditions | Disclaimer At iGeeks Media Pvt Ltd</title>
<meta name="title" content="Terms & Conditions | Disclaimer At iGeeks Media Pvt Ltd" />
<meta name="description" content="iGeeks Media Agreement. The iGeeks Media and its entities are an online information service provider, subject to your compliance with the terms and conditions." />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.igeeksmedia.com/disclaimer/" />
<meta property="og:title" content="Terms & Conditions | Disclaimer At iGeeks Media Pvt Ltd" />
<meta property="og:description" content="iGeeks Media Agreement. The iGeeks Media and its entities are an online information service provider, subject to your compliance with the terms and conditions." />
<meta property="og:image" content="" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.igeeksmedia.com/disclaimer/" />
<meta property="twitter:title" content="Terms & Conditions | Disclaimer At iGeeks Media Pvt Ltd" />
<meta property="twitter:description" content="iGeeks Media Agreement. The iGeeks Media and its entities are an online information service provider, subject to your compliance with the terms and conditions." />
<meta property="twitter:image" content="" />
      </Helmet>
      <div className="policy__div2">
        <div className="home__div4">
          <div className="home__div000">
            <Kawaii />
          </div>
          <div className="home__div5"></div>
          <div className="home__div8 policy_title">
            <p>
              <span>Terms & </span>
              <span>Conditions</span>
            </p>
          </div>
        </div>
      </div>
      <div className="policy_container">
        <h1>iGeeks Media Agreement</h1>
        <br />
        <br />
        <p>
          The iGeeks Media and it’s entities are an online information service
          provider, subject to your compliance with the terms and conditions set
          forth below.
        </p>
        <br />
        <p>
          PLEASE READ THIS DOCUMENT CAREFULLY BEFORE ACCESSING OR USING THE
          SITE. BY ACCESSING OR USING THE SITE, YOU AGREE TO BE BOUND BY THE
          TERMS AND CONDITIONS SET FORTH BELOW. IF YOU DO NOT WISH TO BE BOUND
          BY THESE TERMS AND CONDITIONS, YOU MAY NOT ACCESS OR USE THE SITE.
        </p>
        <br />
        <p>
          iGeeks Media Pvt MAY MODIFY THIS AGREEMENT AT ANY TIME, AND SUCH
          MODIFICATIONS SHALL BE EFFECTIVE IMMEDIATELY UPON POSTING OF THE
          MODIFIED AGREEMENT ON THE SITE. YOU AGREE TO REVIEW THE AGREEMENT
          PERIODICALLY TO BE AWARE OF SUCH MODIFICATIONS AND YOUR CONTINUED
          ACCESS OR USE OF THE SITE SHALL BE DEEMED YOUR CONCLUSIVE ACCEPTANCE
          OF THE MODIFIED AGREEMENT.
        </p>
        <br />
        <br />
        <h3>Copyright, Licenses and Idea Submissions</h3>
        <br />
        <br />
        <p>
          The entire contents of the Site are protected by international
          copyright and trademark laws. The owner of the copyrights and
          trademarks are iGeeks Media, its affiliates or other third party
          licensors.
        </p>
        <br />
        <p>
          YOU MAY NOT MODIFY, COPY, REPRODUCE, REPUBLISH, UPLOAD, POST,
          TRANSMIT, OR DISTRIBUTE, IN ANY MANNER, THE MATERIAL ON THE SITE,
          INCLUDING TEXT, GRAPHICS, CODE AND/OR SOFTWARE.
        </p>
        <br />
        <p>
          You may print and download portions of material from the different
          areas of the Site solely for your own non-commercial use provided that
          you agree not to change or delete any copyright or proprietary notices
          from the materials. You agree to grant to iGeeks Media a
          non-exclusive, royalty-free, worldwide, perpetual license, with the
          right to sub-license, to reproduce, distribute, transmit, create
          derivative works of, publicly display and publicly perform any
          materials and other information (including, without limitation, ideas
          contained therein for new or improved products and services) you
          submit to any public areas of the Site (such as bulletin boards,
          forums and newsgroups) or by e-mail to iGeeks Media by all means and
          in any media now known or hereafter developed.
        </p>
        <br />
        <p>
          You also grant to iGeeks Media the right to use your name in
          connection with the submitted materials and other information as well
          as in connection with all advertising, marketing and promotional
          material related thereto. You agree that you shall have no recourse
          against iGeeks Media for any alleged or actual infringement or
          misappropriation of any proprietary right in your communications to
          iGeeks Media.
        </p>
        <br />
        <br />
        <h3>TRADEMARKS</h3>
        <br />
        <br />
        <p>
          Publications, products, content or services referenced herein or on
          the Site are the exclusive trademarks or service marks of iGeeks
          Media. Other product and company names mentioned in the Site may be
          the trademarks of their respective owners.
        </p>
        <br />
        <br />
        <h3>Use of the Site</h3>
        <br />
        <br />
        <p>
          You understand that, except for information, products or services
          clearly identified as being supplied by iGeeks Media, iGeeks Media
          does not operate, control or endorse any information, products or
          services on the Internet in any way. Except for iGeeks Media -
          identified information, products or services, all information,
          products and services offered through the Site or on the Internet
          generally are offered by third parties, that are not affiliated with
          iGeeks Media a. You also understand that iGeeks Media cannot and does
          not guarantee or warrant that files available for downloading through
          the Site will be free of infection or viruses, worms, Trojan horses or
          other code that manifest contaminating or destructive properties. You
          are responsible for implementing sufficient procedures and checkpoints
          to satisfy your particular requirements for accuracy of data input and
          output, and for maintaining a means external to the Site for the
          reconstruction of any lost data.
        </p>
        <br />
        <p>
          YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THE SITE AND
          THE INTERNET. iGeeks Media PROVIDES THE SITE AND RELATED INFORMATION
          "AS IS" AND DOES NOT MAKE ANY EXPRESS OR IMPLIED WARRANTIES,
          REPRESENTATIONS OR ENDORSEMENTS WHATSOEVER (INCLUDING WITHOUT
          LIMITATION WARRANTIES OF TITLE OR NONINFRINGEMENT, OR THE IMPLIED
          WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE)
          WITH REGARD TO THE SERVICE, ANY MERCHANDISE INFORMATION OR SERVICE
          PROVIDED THROUGH THE SERVICE OR ON THE INTERNET GENERALLY, AND iGeeks
          Media SHALL NOT BE LIABLE FOR ANY COST OR DAMAGE ARISING EITHER
          DIRECTLY OR INDIRECTLY FROM ANY SUCH TRANSACTION. IT IS SOLELY YOUR
          RESPONSIBILITY TO EVALUATE THE ACCURACY, COMPLETENESS AND USEFULNESS
          OF ALL OPINIONS, ADVICE, SERVICES, MERCHANDISE AND OTHER INFORMATION
          PROVIDED THROUGH THE SERVICE OR ON THE INTERNET GENERALLY. iGeeks
          Media DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED OR
          ERROR-FREE OR THAT DEFECTS IN THE SERVICE WILL BE CORRECTED.
        </p>
        <br />
        <p>
          YOU UNDERSTAND FURTHER THAT THE PURE NATURE OF THE INTERNET CONTAINS
          UNEDITED MATERIALS SOME OF WHICH ARE SEXUALLY EXPLICIT OR MAY BE
          OFFENSIVE TO YOU. YOUR ACCESS TO SUCH MATERIALS IS AT YOUR RISK.
          iGeeks Media HAS NO CONTROL OVER AND ACCEPTS NO RESPONSIBILITY
          WHATSOEVER FOR SUCH MATERIALS.
        </p>
        <br />
        <br />
        <h3>Limitation of Liability</h3>
        <br />
        <br />
        <p>
          IN NO EVENT WILL iGeeks Media BE LIABLE FOR (I) ANY INCIDENTAL,
          CONSEQUENTIAL, OR INDIRECT DAMAGES (INCLUDING, BUT NOT LIMITED TO,
          DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF PROGRAMS
          OR INFORMATION, AND THE LIKE) ARISING OUT OF THE USE OF OR INABILITY
          TO USE THE SERVICE, OR ANY INFORMATION, OR TRANSACTIONS PROVIDED ON
          THE SERVICE, OR DOWNLOADED FROM THE SERVICE, OR ANY DELAY OF SUCH
          INFORMATION OR SERVICE. EVEN IF iGeeks Media OR ITS AUTHORIZED
          REPRESENTATIVES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
          OR (II) ANY CLAIM ATTRIBUTABLE TO ERRORS, OMISSIONS, OR OTHER
          INACCURACIES IN THE SERVICE AND/OR MATERIALS OR INFORMATION DOWNLOADED
          THROUGH THE SERVICE. BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR
          LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE
          ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH STATES, iGeeks Media
          LIABILITY IS LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
        </p>
        <br />
        <p>
          iGeeks Media makes no representations whatsoever about any other web
          site which you may access through this one or which may link to this
          Site. When you access a non-iGeeks Media web site, please understand
          that it is independent from iGeeks Media, and that iGeeks Media has no
          control over the content on that web site. In addition, a link to a
          iGeeks Media web site does not mean that iGeeks Media endorses or
          accepts any responsibility for the content, or the use, of such web
          site.
        </p>
        <br />
        <br />
        <h3>Comments</h3>
        <br />
        <br />
        <p>
          iGeeks Media continuously monitors Comments section. Our editorial
          team holds the right to moderate your comments, and after thorough
          moderation, comments will be approved/uploaded on our website. iGeeks
          Media is not responsible for the information or content posted in the
          Comments. To make our Comments section interactive, useful, and
          interesting, we have set up a few guidelines for our users/readers:
        </p>
        <br />
        <p>
          - Your comments must not threaten, harass, defame, or slander any
          person/institute/organization/party/community/country or any living or
          non-living entity. - iGeeks Media does not entertain any false or
          misleading information/content. - Comments must not promote any
          product or service overtly or covertly. - Comments must not violate
          copyright or any other intellectual property rights/interests. -
          iGeeks Media may reject or delete any comment, information, or content
          that is confidential, sensitive or is against the law. - All comments
          must be relevant to the topics under which your comment is posted.
        </p>
        <br />
        <p>
          Users/readers are advised to post comments worded judiciously. Once
          the comments are approved and published on iGeeks Media, they are part
          of public conversation. Removal of your comments is the sole decision
          of our editorial team; you cannot force our editors to make a change
          or delete comments just because you have changed your mind on your
          comments.
        </p>
        <br />
        <p>
          iGeeks Media holds the right to remove your comments and comment
          accounts at its discretion.
        </p>
        <br />
        <p>
          iGeeks Media does not claim the authenticity of user comments. If
          anybody (third-party) questions or raises an objection against user
          comments or if any third-party finds that user comments violate our
          Privacy Policy, Editorial Policy, or Terms of Use, or their rights,
          iGeeks Media will invite them to reply in the comments section
          themselves. In case they raise a legal objection, we will make sincere
          efforts to contact you through the source you have provided us.
        </p>
        <br />
        <p>
          iGeeks Media will protect your contact details as mentioned in our
          Privacy Policy; however, we may divulge your contact information in
          the event of the legal process.
        </p>
        <br />
        <br />
        <h3>RSS Feed</h3>
        <br />
        <br />
        <p>
          You can subscribe to iGeeks Media RSS feeds or integrate the feeds
          into your blog/website. To use our feed, you must not modify it in any
          way; this includes the removal of advertisements and adherence to
          below-mentioned guidelines:
        </p>
        <br />
        <p>
          - Full ad-supported feeds are intended only for personal use in RSS
          readers. - For all other feed uses, including reproduction on
          websites, you should only use the - Excerpt feeds, unless otherwise
          agreed with the company. - Do not splice the RSS feeds into or
          otherwise re-share or redistribute them via third-party RSS providers.
          - Do not add content, including any advertisements or other
          promotional content, to the RSS feeds. - iGeeks Media reserves the
          right to object to your presentation of the RSS feeds and the right to
          require you to stop using the RSS feeds at any time. - iGeeks Media
          further reserves the right to discontinue its distribution of the RSS
          feeds or change the content or formatting of the RSS feeds at any time
          without notice to you.
        </p>
        <br />
        <br />
        <h3>Indemnification</h3>
        <br />
        <br />
        <p>
          You agree to indemnify, defend and hold harmless iGeeks Media, its
          officers, directors, employees, agents, licensors, suppliers and any
          third party information providers to the Service from and against all
          losses, expenses, damages and costs, including reasonable attorneys'
          fees, resulting from any violation of this Agreement (including
          negligent or wrongful conduct) by you or any other person accessing
          the Service.
        </p>
        <br />
        <br />
        <h3>Third-Party Rights</h3>
        <br />
        <br />
        <p>
          The provisions of paragraphs 2 (Use of the Service), and 3
          (Indemnification) are for the benefit of iGeeks Media and its
          officers, directors, employees, agents, licensors, suppliers, and any
          third party information providers to the Service. Each of these
          individuals or entities shall have the right to assert and enforce
          those provisions directly against you on its own behalf.
        </p>
        <br />
        <br />
        <h3>Term; Termination</h3>
        <br />
        <br />
        <p>
          This Agreement may be terminated by either party without notice at any
          time for any reason. The provisions of paragraphs 1 (Copyright,
          Licenses and Idea Submissions), 2 (Use of the Service), 3
          (Indemnification), 4 (Third Party Rights) and 6 (Miscellaneous) shall
          survive any termination of this Agreement.
        </p>
        <br />
        <br />
        <h3>Miscellaneous</h3>
        <br />
        <br />
        <p>
          This Agreement shall all be governed and construed in accordance with
          the laws of India applicable to agreements made and to be performed in
          India. You agree that any legal action or proceeding between iGeeks
          Media and you for any purpose concerning this Agreement or the
          parties' obligations hereunder shall be brought exclusively in a
          federal or state court of competent jurisdiction sitting in India. Any
          cause of action or claim you may have with respect to the Service must
          be commenced within one (1) year after the claim or cause of action
          arises or such claim or cause of action is barred. iGeeks Media's
          failure to insist upon or enforce strict performance of any provision
          of this Agreement shall not be construed as a waiver of any provision
          or right. Neither the course of conduct between the parties nor trade
          practice shall act to modify any provision of this Agreement. iGeeks
          Media may assign its rights and duties under this Agreement to any
          party at any time without notice to you.
        </p>
        <br />
        <p>Any rights not expressly granted herein are reserved.</p>
      </div>
    </div>
    </HelmetProvider>
  );
}

export default Terms;

export function buttonClick() {
  document.getElementById("navbar__div4").style.height = "80vh";
  document.getElementById("navbar__div4").style.width = "91vw";
  document.getElementById("navbar__div4").style.transition = "0.7s ease";
  document.getElementById("ham__div1").style.display = "block";
  document.getElementById("hamburger_close").style.display = "block";
  document.getElementById("navbar__div9").style.display = "none";
  document.body.style.overflowY = "hidden";
  document.querySelector('html').style.overflowY = "hidden";
  document.getElementById("navbar__div4").style.borderRadius = "0%";
  document.getElementById("navbar__div5").style.marginTop = "2vw";
  document.getElementById("logo_div2").style.display = "none";  
  document.getElementById("whole_container").style.opacity = 0;
  document.getElementById("navbar__div8").style.color = "#000";
  document.getElementById("navbar__div8").style.opacity = 0;
  document.getElementById("navbar__div80").style.opacity = 1;
  document.getElementById("navbar__div80").style.color = "#000";
  document.getElementById("navbar__div7").style.marginTop = "2vw";
  document.getElementById("navbar__div7").style.marginLeft = "-2vw";
  document.getElementById("navbar__div7").style.transition = "0.7s ease";
    document.getElementById("navbar__lgoSvg").style.marginTop = "0vw";
    document.getElementById("navbar__lgoSvg").style.top = "0vw";
    document.getElementById("navbar__lgoSvg").style.marginLeft = "0vw";
    document.getElementById("navbar__lgoSvg").style.width = "33vmin";
    document.getElementById("navbar__lgoSvg").style.opacity = "1";
    document.getElementById("nav__logoSvg2").style.width = "0vmin";
    document.getElementById("nav__logoSvg2").style.opacity = "0";
}

export function closeClicks() {
  document.getElementById("navbar__div4").style.transition = "0.7s ease";
  document.getElementById("hamburger_close").style.display = "none";
  document.getElementById("navbar__div9").style.display = "block";
  document.body.style.overflowY = "unset";
  document.querySelector('html').style.overflowY = "unset";
  document.getElementById("navbar__div4").style.borderRadius = "50%";
  document.getElementById("navbar__div5").style.marginTop = "0";
  document.getElementById("logo_div2").style.display = "flex";
  document.getElementById("whole_container").style.opacity = 1;
  document.getElementById("navbar__div8").style.color = "#f2d729";
  document.getElementById("navbar__div8").style.opacity = 1;
  document.getElementById("navbar__div80").style.opacity = 0;
  document.getElementById("navbar__div7").style.marginTop = "0vw";
  document.getElementById("navbar__div7").style.marginLeft = "0vw";
  document.getElementById("navbar__div7").style.transition = "0.7s ease";
    document.getElementById("navbar__div4").style.height = "9.6vmin";
    document.getElementById("navbar__div4").style.width = "10vmin";
    document.getElementById("navbar__lgoSvg").style.width = "0vmin";
    document.getElementById("navbar__lgoSvg").style.opacity = "0";
    document.getElementById("nav__logoSvg2").style.width = "7vmin";
    document.getElementById("nav__logoSvg2").style.opacity = "1";
}

export function buttonClicks() {
  document.getElementById("navbar__div4").style.height = "170vw";
  document.getElementById("navbar__div4").style.width = "91vw";
  document.getElementById("navbar__div4").style.transition = "0.6s ease";
  document.getElementById("ham__div1").style.display = "block";
  document.getElementById("hamburger_close").style.display = "block";
  document.getElementById("navbar__div9").style.display = "none";
  document.body.style.overflowY = "hidden";
  document.querySelector('html').style.overflowY = "hidden";
  document.getElementById("navbar__div4").style.borderRadius = "0%";
  document.getElementById("navbar__div5").style.marginTop = "2vw";
  document.getElementById("logo_div2").style.display = "none";
  document.getElementById("whole_container").style.opacity = 0;
  document.getElementById("navbar__div8").style.color = "#000";
  document.getElementById("navbar__div8").style.opacity = 0;
  document.getElementById("navbar__div80").style.opacity = 1;
  document.getElementById("navbar__div80").style.color = "#000";
  document.getElementById("navbar__div7").style.marginTop = "2vw";
  document.getElementById("navbar__div7").style.marginLeft = "-2vw";
  document.getElementById("navbar__div7").style.transition = "0.6s ease";
  document.getElementById("navbar__lgoSvg").style.marginTop = "2vw";
  document.getElementById("navbar__lgoSvg").style.width = "33vmin";
    document.getElementById("navbar__lgoSvg").style.opacity = "1";
}

export function scrollFunction() {
  if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
    document.getElementById("navbar__div4").style.height = "9.6vmin";
    document.getElementById("navbar__div4").style.width = "10vmin";
    document.getElementById("navbar__div4").style.borderRadius = "50%";
    document.getElementById("navbar__div5").style.marginTop = "0";
    document.getElementById("navbar__lgoSvg").style.position = "relative";
    document.getElementById("navbar__lgoSvg").style.top = "-1vw";
    document.getElementById("navbar__lgoSvg").style.marginLeft = "-1vw";
    document.getElementById("navbar__lgoSvg").style.width = "0vmin";
    document.getElementById("navbar__lgoSvg").style.opacity = "0";
    document.getElementById("nav__logoSvg2").style.width = "7vmin";
    document.getElementById("nav__logoSvg2").style.opacity = "1";
    document.getElementById("navbar__div8").style.opacity = 0;
  } else {
    document.getElementById("navbar__div4").style.height = "20vmax";
    document.getElementById("navbar__div4").style.width = "40vmin";
    document.getElementById("navbar__div4").style.borderRadius = "0%";
    document.getElementById("navbar__div5").style.marginTop = "2vw";
    document.getElementById("navbar__lgoSvg").style.marginTop = "0vw";
    document.getElementById("navbar__lgoSvg").style.top = "0vw";
    document.getElementById("navbar__lgoSvg").style.marginLeft = "0vw";
    document.getElementById("navbar__lgoSvg").style.width = "33vmin";
    document.getElementById("navbar__lgoSvg").style.opacity = "1";
    document.getElementById("nav__logoSvg2").style.width = "0vmin";
    document.getElementById("nav__logoSvg2").style.opacity = "0";
    document.getElementById("navbar__div8").style.opacity = 1;
  }
}

export function carrerPage() {
  document.body.style.overflowY = "unset";
  document.body.style.backgroundColor = "#222";
  document.querySelector('html').style.overflowY = "unset";
  document.getElementById("whole_container").style.opacity = 0;
  window.scrollTo(0,0); 
  // setTimeout( ()=>{window.location.reload()}, 100);
  closeClicks();
  // history.push("/career");
}

export function contact() {
  document.body.style.overflowY = "unset";
  document.body.style.backgroundColor = "#222";
  document.querySelector('html').style.overflowY = "unset";
  document.getElementById("whole_container").style.opacity = 0;
  window.scrollTo(0,0); 
  // setTimeout(()=>{window.location.reload()}, 100);
  closeClicks();
  // history.push("/contact");
}

export function privacyPolicy() {
  document.body.style.overflowY = "unset";
  document.body.style.backgroundColor = "#222";
  document.querySelector('html').style.overflowY = "unset";
  document.getElementById("whole_container").style.opacity = 0;
  window.scrollTo(0,0);
  // setTimeout( ()=>{window.location.reload()}, 100);
  closeClicks();
  // history.push("/policies");
}

export function termsCondition() {
  document.body.style.overflowY = "unset";
  document.body.style.backgroundColor = "#222";
  document.querySelector('html').style.overflowY = "unset";
  document.getElementById("whole_container").style.opacity = 0;
  window.scrollTo(0,0); 
  // setTimeout( ()=>{window.location.reload()}, 100);
  closeClicks();
  // history.push("/terms");
}

export function ourTeam() {
  document.body.style.overflowY = "unset";
  document.body.style.backgroundColor = "#222";
  document.querySelector('html').style.overflowY = "unset";
  document.getElementById("whole_container").style.opacity = 0;
  window.scrollTo(0,0);
  // setTimeout( ()=>{window.location.reload()}, 100);
  closeClicks();
  // history.push("/terms");
}

export const itemMain = {
  hidden: {
    opacity: 1,
    height: "87.5vh",
    width: "93vw",
    position: "relative",
    zIndex: 1,
    overflow: "hidden",
  },
  show: {
    opacity: 1,
    height: "20vmax",
    width: "42vmin",
    transition: {
      easeInOut: [0.85, 0.01, -0.05, 0.65],
      duration: 0.7,
      delay: 1,
    },
  },
  exit: {
    opacity: 1,
  },
};

export const itemMains = {
  hidden: {
    opacity: 1,
    height: "90vh",
    width: "90vw",
    position: "relative",
    zIndex: 1,
    overflow: "hidden",
  },
  show: {
    opacity: 1,
    height: "20vmax",
    width: "42vmin",
    transition: {
      easeInOut: [0.85, 0.01, -0.05, 0.65],
      duration: 0.7,
      delay: 1,
    },
  },
  exit: {
    opacity: 1,
  },
};

export const svgAnimation = {
  hidden: {
    marginTop: "23vw",
    marginLeft: "35vw",
  },
  show: {
    marginTop: "2vw",
    marginLeft: "0vw",
    transition: {
      easeInOut: [0.85, 0.01, -0.05, 0.65],
      duration: 0.9,
      delay: 1.3,
    },
  },
  exit: {
    opacity: 1,
  },
};

export const svgAnimations = {
  hidden: {
    marginTop: "75vw",
    marginLeft: "27vw",
  },
  show: {
    marginTop: "3vw",
    marginLeft: "1vw",
    transition: {
      easeInOut: [0.85, 0.01, -0.05, 0.65],
      duration: 0.9,
      delay: 1.3,
    },
  },
  exit: {
    opacity: 1,
  },
};

import React, { useLayoutEffect } from "react";
import Kawaii from "../components/blob/Kawaii";

import { motion } from "framer-motion";

// React Helmet
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Content() {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
      });
  return (
    <div> 
        <HelmetProvider>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: "0.5s all ease" }}
          exit={{ opacity: 0 }}
          className="carrer__div1"
        >
          <Helmet>
          {/* Primary Meta Tags */}
<title>Creative and Tech Content Creators | Social Media</title>
<meta name="title" content="Creative and Tech Content Creators | Social Media" />
<meta name="description" content="We seek Creative and Tech Content creators for
iGeeksBlog's social profiles across YouTube, Instagram, and Twitter.
Want to be the face of iGeeksBlog? Apply Now!" />

{/* Open Graph / Facebook */}
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.igeeksmedia.com/content-creator/" />
<meta property="og:title" content="Creative and Tech Content Creators | Social Media" />
<meta property="og:description" content="We seek Creative and Tech Content creators for
iGeeksBlog's social profiles across YouTube, Instagram, and Twitter.
Want to be the face of iGeeksBlog? Apply Now!" />
<meta property="og:image" content="" />

{/* Twitter */}
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.igeeksmedia.com/content-creator/" />
<meta property="twitter:title" content="Creative and Tech Content Creators | Social Media" />
<meta property="twitter:description" content="We seek Creative and Tech Content creators for
iGeeksBlog's social profiles across YouTube, Instagram, and Twitter.
Want to be the face of iGeeksBlog? Apply Now!" />
<meta property="twitter:image" content="" />
          </Helmet>
          <div className="carrer__div2">
            <div className="home__div4">
              <div className="home__div000">
                <Kawaii />
              </div>
              <div className="home__div5"></div>
              <div className="carrer__div8000">
                <p>
                  <span>Content </span>
                  <span>Creator</span>
                </p>
              </div>
            </div>
          </div>
          <div className="staff__div1">
            <div className="arrer__extra1">
              <div className="carrer__div9"></div>
              <div className="carrer__div10">
                <div className="carrer__div11">
                  <div className="carrer__div12">
                    <p>Writing</p>
                  </div>
                  <div className="carrer__div13">
                    <p>Content Creator</p>
                  </div>
                </div>
                <div className="carrer__div14">
                  <div className="carrer__div15">
                    <p>About</p>
                  </div>
                  <div className="carrer__div16">
                    <p>
                    iGeeks is looking for a content creator & Apple fan.Someone who can work with teams handling different projects, across the organization, to write engaging content for humans and not just search engines. The ask is to write content that enhances user experience across Apple's ecosystem whilst keeping it engaging and easy to understand.
                    </p>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="staff__div2">
            <div className="staff__div3">
              <p>Interested?</p>
              <div className="apply_Button">
                <a href="mailto:hr@igeeksmedia.com" target="_blank" rel="noreferrer">Apply Now</a>
              </div>
            </div>
            <div className="staff__div4">
              <p>Requirements:</p>
              <div className="staff__div5">
                <div className="staff__div6">
                  <p>Key Responsibilities:</p>
                </div>
                <div className="staff__div7">
                  <ul>
                    <li>
                      Writing for consumer-technologies with the focus on Apple,
                      smartphones, gadgets, and tech-accessories.
                    </li>
                    <li>
                      Create, maintain and regularly update buying guides, how-tos,
                      reviews, and editorials.
                    </li>
                    <li>
                      The content should be grammatically correct and with an easy
                      to understand language.
                    </li>
                    <li>Research new content ideas independently.</li>
                    <li>
                      Keep track of latest development and news from the consumer
                      tech.
                    </li>
                    <li>Trying new Apps, Software, Accessories, and Gadgets.</li>
                  </ul>
                </div>
              </div>
              <div className="staff__div50">
                <div className="staff__div6">
                  <p>Skills set to get succeed:</p>
                </div>
                <div className="staff__div7">
                  <ul>
                    <li>1 to 5 years of experience in writing.</li>
                    <li>
                      Has been a tech blogger, will be consider as an advantage.
                    </li>
                    <li>
                      Good command over English in writing and verbal as well.
                    </li>
                    <li>Must be good at explaining the things.</li>
                    <li>
                      Degree in Mass Communication will be an added advantage.
                    </li>
                    <li>Trying new Apps, Software, Accessories, and Gadgets.</li>
                    <li>Knowledge of SEO.</li>
                  </ul>
                </div>
              </div>
              {/* <div className="staff__div50">
                <div className="staff__div6">
                  <p>What will you get in return?</p>
                </div>
                <div className="staff__div7">
                  <ul>
                    <li>Flexible Timing</li>
                    <li>Family Mediclaim & Accident Policy</li>
                    <li>
                      A share in our success - Get a chance to receive a profit pool
                      bonus at the end of our financial year
                    </li>
                    <li>
                      Healthy snacks, fruits, beverages and lunch on every working
                      Saturday.
                    </li>
                    <li>
                      Fitness facilities (gym, yoga membership) at discount price
                    </li>
                    <li>
                      Free digital magazine subscriptions of Magzter/Bookazines
                    </li>
                    <li>
                      While working you can refresh yourself by playing Foosball,
                      tabled basketball, or compete with other on Xbox/Play Station.
                    </li>
                    <li>Library access</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </motion.div>
        </HelmetProvider></div>
  )
}

export default Content
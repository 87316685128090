import React from "react";
// import { Link } from "react-router-dom";

import first from "../../images-webp/1-1.webp";
import second from "../../images-webp/2-1.webp";
import three from "../../images-webp/3.webp";
import four from "../../images-webp/4.webp";
import five from "../../images-webp/5.webp";
import six from "../../images-webp/6.webp";
import seven from "../../images-webp/7.webp";
import eight from "../../images-webp/8.webp";
import nine from "../../images-webp/9.webp";
import ten from "../../images-webp/brand10.webp";
import eleven from "../../images-webp/brand11.webp";
import twelve from "../../images-webp/brand12.webp";
import thirteen from "../../images-webp/brand13.webp";
import forteen from "../../images-webp/brand14.webp";
import fifteen from "../../images-webp/brand15.webp";
import sixteen from "../../images-webp/brand16.webp";
import seventeen from "../../images-webp/brand17.webp";
import eighteen from "../../images-webp/brand18.webp";
import ninteen from "../../images-webp/brand19.webp";
import twenty from "../../images-webp/brand20.webp";
import twentyFirst from "../../images-webp/brand21.png";
import twentySecond from "../../images-webp/brand22.png";

function HomeBrandCollaboration() {
  return (
    <div className="home__div61">
      <div className="home__div38">
        <div className="home__div39">
          <div className="home__div40">
            <div className="home__div41"></div>
            <div className="home__div42">
              <p>Brand</p>
              <p>Collaboration</p>
            </div>
          </div>
          <div className="home__div43"></div>
        </div>
      </div>
      <div className="home__div62">
        <div className="home__div620Extra" id="home__div63">
          <div className="home__div63">
            <img src={first} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={second} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={three} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={four} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={five} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={six} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={seven} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={eight} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={nine} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={ten} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={eleven} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={twelve} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={thirteen} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={forteen} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={fifteen} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={sixteen} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={seventeen} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={eighteen} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={ninteen} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={twenty} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={twentyFirst} className="home__brand1" alt="" />
          </div>
          <div className="home__div63">
            <img src={twentySecond} className="home__brand1" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeBrandCollaboration;

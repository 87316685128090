import React, { useLayoutEffect } from "react";
import Kawaii from "../components/blob/Kawaii";

import { motion } from "framer-motion";

// React Helmet
import { Helmet, HelmetProvider } from "react-helmet-async";

function Copywriter() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <HelmetProvider>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: "0.5s all ease" }}
          exit={{ opacity: 0 }}
          className="carrer__div1"
        >
          <Helmet>
          {/* Primary Meta Tags */}
<title>Hiring Now - Creative Copywriter at iGeeksBlog.com</title>
<meta name="title" content="Hiring Now - Creative Copywriter at iGeeksBlog.com" />
<meta name="description" content="Who doesn’t appreciate a good copy? So, if you are an
expert copywriter who can deliver the wow factor with words, join us
today." />

{/* Open Graph / Facebook */}
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.igeeksmedia.com/copywriter/" />
<meta property="og:title" content="Hiring Now - Creative Copywriter at iGeeksBlog.com" />
<meta property="og:description" content="Who doesn’t appreciate a good copy? So, if you are an
expert copywriter who can deliver the wow factor with words, join us
today." />
<meta property="og:image" content="" />

{/* Twitter */}
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.igeeksmedia.com/copywriter/" />
<meta property="twitter:title" content="Hiring Now - Creative Copywriter at iGeeksBlog.com" />
<meta property="twitter:description" content="Who doesn’t appreciate a good copy? So, if you are an
expert copywriter who can deliver the wow factor with words, join us
today." />
<meta property="twitter:image" content="" />
          </Helmet>
          <div className="carrer__div2">
            <div className="home__div4">
              <div className="home__div000">
                <Kawaii />
              </div>
              <div className="home__div5"></div>
              <div className="carrer__div8000">
                <p>
                  <span>Copywriter</span>
                </p>
              </div>
            </div>
          </div>
          <div className="staff__div1">
            <div className="arrer__extra1">
              <div className="carrer__div9"></div>
              <div className="carrer__div10">
                <div className="carrer__div11">
                  <div className="carrer__div12">
                    <p>Writing</p>
                  </div>
                  <div className="carrer__div13">
                    <p>Copywiter</p>
                  </div>
                </div>
                <div className="carrer__div14">
                  <div className="carrer__div15">
                    <p>About</p>
                  </div>
                  <div className="carrer__div16">
                    <p>
                      We are looking for a creative Copywriter to write clear
                      and concise copy for ads, publications and websites. Your
                      words will inform and engage target audiences. Our ideal
                      candidate is a team-spirited, skilled and imaginative
                      writer with an eye for detail. If you can grasp project
                      requirements quickly and offer valuable insight, we’d like
                      to meet you.
                    </p>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="staff__div2">
            <div className="staff__div3">
              <p>Interested?</p>
              <div className="apply_Button">
                <a
                  href="mailto:hr@igeeksmedia.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Apply Now
                </a>
              </div>
            </div>
            <div className="staff__div4">
              <p>Requirements:</p>
              <div className="staff__div5">
                <div className="staff__div6">
                  <p>Key Responsibilities:</p>
                </div>
                <div className="staff__div7">
                  <ul>
                    <li>
                      Writing for consumer-technologies with the focus on Apple,
                      smartphones, gadgets, and tech-accessories.
                    </li>
                    <li>
                      Create, maintain and regularly update buying guides,
                      how-tos, reviews, and editorials.
                    </li>
                    <li>
                      The content should be grammatically correct and with an
                      easy to understand language.
                    </li>
                    <li>Research new content ideas independently.</li>
                    <li>
                      Keep track of latest development and news from the
                      consumer tech.
                    </li>
                    <li>
                      Trying new Apps, Software, Accessories, and Gadgets.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="staff__div50">
                <div className="staff__div6">
                  <p>Skills set to get succeed:</p>
                </div>
                <div className="staff__div7">
                  <ul>
                    <li>1 to 5 years of experience in writing.</li>
                    <li>
                      Has been a tech blogger, will be consider as an advantage.
                    </li>
                    <li>
                      Good command over English in writing and verbal as well.
                    </li>
                    <li>Must be good at explaining the things.</li>
                    <li>
                      Degree in Mass Communication will be an added advantage.
                    </li>
                    <li>
                      Trying new Apps, Software, Accessories, and Gadgets.
                    </li>
                    <li>Knowledge of SEO.</li>
                  </ul>
                </div>
              </div>
              {/* <div className="staff__div50">
                <div className="staff__div6">
                  <p>What will you get in return?</p>
                </div>
                <div className="staff__div7">
                  <ul>
                    <li>Flexible Timing</li>
                    <li>Family Mediclaim & Accident Policy</li>
                    <li>
                      A share in our success - Get a chance to receive a profit
                      pool bonus at the end of our financial year
                    </li>
                    <li>
                      Healthy snacks, fruits, beverages and lunch on every
                      working Saturday.
                    </li>
                    <li>
                      Fitness facilities (gym, yoga membership) at discount
                      price
                    </li>
                    <li>
                      Free digital magazine subscriptions of Magzter/Bookazines
                    </li>
                    <li>
                      While working you can refresh yourself by playing
                      Foosball, tabled basketball, or compete with other on
                      Xbox/Play Station.
                    </li>
                    <li>Library access</li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </motion.div>
      </HelmetProvider>
    </div>
  );
}

export default Copywriter;

import React from 'react'
import "../styles/Contact.css"
import Kawaii from "../components/blob/Kawaii";
// React Helmet
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Contact() {
  return (
    <HelmetProvider>
    <div className="contact__div1">
      <Helmet>
      {/* Primary Meta Tags */}
<title>Contact us today for Specialist Tech Support and Solutions</title>
<meta name="title" content="Contact us today for Specialist Tech Support and Solutions" />
<meta name="description" content="Contact us to explore exciting opportunities for
collaboration, feedback, or assistance. Our professional tech team is
always eager to connect and assist you in any way we can. Contact us
today!" />

{/* Open Graph / Facebook */}
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.igeeksmedia.com/contact/" />
<meta property="og:title" content="Contact us today for Specialist Tech Support and Solutions" />
<meta property="og:description" content=" Contact us to explore exciting opportunities for
collaboration, feedback, or assistance. Our professional tech team is
always eager to connect and assist you in any way we can. Contact us
today!" />
<meta property="og:image" content="" />

{/* Twitter */}
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.igeeksmedia.com/contact/" />
<meta property="twitter:title" content="Contact us today for Specialist Tech Support and Solutions" />
<meta property="twitter:description" content="Contact us to explore exciting opportunities for
collaboration, feedback, or assistance. Our professional tech team is
always eager to connect and assist you in any way we can. Contact us
today!" />
<meta property="twitter:image" content="" />
      </Helmet>
      <div className='contact__div2'>
        <div className='home__div4'>
            <div className='home__div000'><Kawaii /></div>
            <div className='home__div5'>
                
            </div>
            <div className='home__div8 policy_title'>
                <p><span>Contact </span><span>Us</span></p>
            </div>
        </div>
    </div>
    <div className="contact">
      <div className="contact_div1">
        <h1>iGeeks Media Pvt Ltd.</h1>
        <div>
          <p>A 1202, Ratnakar Nine Square,</p>
          <p>Opp. Keshavbaug, Mansi Circle Road,</p>
          <p>Vastrapur - Ahmedabad</p>
          <p>Gujarat (Ind)</p>
          <p>Zip: 380015</p>
        </div>
      </div>
      <div className="contact_div2">
        <h1>EMAIL US:</h1>
        <h2><a href="mailto:info@igeeksmedia.com" target="_blank" rel="noreferrer">📧 info@igeeksmedia.com</a></h2>
        <h1>WORKING HOUR:</h1>
        <p>⏰ Monday - Friday: 10:00 - 18:00</p>
      </div>
      <div className="contact_div3">
        <h1>VISIT US TODAY</h1>
      <iframe title='Address' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.7742927858703!2d72.52514!3d23.032058099999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84b62b4e39d5%3A0xb1084d4303790a0f!2siGeeks%20Media%20Private%20Limited!5e0!3m2!1sen!2sin!4v1674048001783!5m2!1sen!2sin" className='google_map' style={{border: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
    </div>
    </HelmetProvider>
  )
}

export default Contact;
import { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/Carrer.css";
import Kawaii from "../components/blob/Kawaii";
import { motion } from "framer-motion";
// React Helmet
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Carrer() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <HelmetProvider>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: "0.5s all ease" }}
      exit={{ opacity: 0 }}
      className="carrer__div1"
    >
      <Helmet>
      {/* <!-- Primary Meta Tags --> */}
<title>We are Hiring | Current openings at iGeeks Media Pvt. Ltd.</title>
<meta name="title" content="We are Hiring | Current openings at iGeeks Media Pvt. Ltd." />
<meta name="description" content="iGeeks Media Pvt. Ltd. is always open to working with
creative Apple fans. So, check out our current openings and email us
your updated resume today." />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.igeeksmedia.com/career/" />
<meta property="og:title" content="We are Hiring | Current openings at iGeeks Media Pvt. Ltd." />
<meta property="og:description" content="iGeeks Media Pvt. Ltd. is always open to working with
creative Apple fans. So, check out our current openings and email us
your updated resume today." />
<meta property="og:image" content="" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.igeeksmedia.com/career/" />
<meta property="twitter:title" content="We are Hiring | Current openings at iGeeks Media Pvt. Ltd." />
<meta property="twitter:description" content="iGeeks Media Pvt. Ltd. is always open to working with
creative Apple fans. So, check out our current openings and email us
your updated resume today." />
<meta property="twitter:image" content="" />
      </Helmet>
      <div className="carrer__div2">
        <div className="home__div4">
          <div className="home__div000">
            <Kawaii />
          </div>
          <div className="home__div5"></div>
          <div className="carrer__div8">
            <p>
              <span>Career </span>
              <span>Opportunities</span>
            </p>
          </div>
        </div>
      </div>
      {/* <Link to="/staff" style={{ textDecoration: "none", color: "#fff" }}> */}
        <motion.div className="arrer__extra1">
          <div className="carrer__div9"></div>
          <div className="carrer__div10">
            <div className="carrer__div11">
              <div className="carrer__div12">
                <p>Writing</p>
              </div>
              <div className="carrer__div13">
                <p>Tech Writer</p>
              </div>
            </div>
            <div className="carrer__div14">
              <div className="carrer__div15">
                <p>About</p>
              </div>
              <div className="carrer__div16">
                <p>
                iGeeks is looking for a tech writer & Apple fan.Someone who can work with teams handling different projects, across the organization, to write engaging content for humans and not just search engines. The ask is to write content that enhances user experience across Apple's ecosystem whilst keeping it engaging and easy to understand. <Link to="/content-writer/" style={{ color: "#f2d729" }}>Know More</Link>
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </motion.div>
      {/* </Link> */}
      {/* <Link to="/seo" style={{ textDecoration: "none", color: "#fff" }}> */}
        <div className="arrer__extra10">
          <div className="carrer__div9"></div>
          <div className="carrer__div10">
            <div className="carrer__div11">
              <div className="carrer__div12">
                <p>SEO</p>
              </div>
              <div className="carrer__div13">
                <p>SEO Executive</p>
              </div>
            </div>
            <div className="carrer__div14">
              <div className="carrer__div15">
                <p>About</p>
              </div>
              <div className="carrer__div16">
                <p>
                We are looking for an SEO Executive to join our Marketing team and help us build a strong online presence. To be successful as an SEO Executive, you should be passionate about technology and digital communications. <Link to="/search-engine-optimization/" style={{ color: "#f2d729" }}>Know More</Link>
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      {/* </Link> */}
      {/* <Link to="/marketer" style={{ textDecoration: "none", color: "#fff" }}> */}
        <div className="arrer__extra10" style={{display: "none"}}>
          <div className="carrer__div9"></div>
          <div className="carrer__div10">
            <div className="carrer__div11">
              <div className="carrer__div12">
                <p>Marketer</p>
              </div>
              <div className="carrer__div13">
                <p>Content Marketer</p>
              </div>
            </div>
            <div className="carrer__div14">
              <div className="carrer__div15">
                <p>About</p>
              </div>
              <div className="carrer__div16">
                <p>
                We are looking for an exceptional content marketer to join our team. You will be in charge of reviewing articles, blogs, webpages, e-books, and other copy produced by content writers. You will ensure the content meets the company’s brand character and quality benchmarks. Success in this position will be demonstrated by producing content that increases engagement with the company’s products and contributes to the company’s growth as per set KPIs. <Link to="content-marketer/" style={{ color: "#f2d729" }}>Know More</Link>
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      {/* </Link> */}
      {/* <Link to="/social" style={{ textDecoration: "none", color: "#fff" }}> */}
        <div className="arrer__extra10" style={{display: "none"}}>
          <div className="carrer__div9"></div>
          <div className="carrer__div10">
            <div className="carrer__div11">
              <div className="carrer__div12">
                <p>Media</p>
              </div>
              <div className="carrer__div13">
                <p>Social Media Strategist</p>
              </div>
            </div>
            <div className="carrer__div14">
              <div className="carrer__div15">
                <p>About</p>
              </div>
              <div className="carrer__div16">
                <p>
                To meet our business growth, we are looking for a talented Social Media Manager to administer our social media accounts. The role holder will be responsible for planning original text and video content, managing posts etc. Further, the candidate will handle social media presence ensuring high levels of web traffic and customer engagement while managing company and client image in a cohesive way to achieve the defined marketing goals. <Link to="/social-media/" style={{ color: "#f2d729" }}>Know More</Link>
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      {/* </Link> */}
      {/* <Link to="/graphic" style={{ textDecoration: "none", color: "#fff" }}> */}
        <div className="arrer__extra10" style={{display: "none"}}>
          <div className="carrer__div9"></div>
          <div className="carrer__div10">
            <div className="carrer__div11">
              <div className="carrer__div12">
                <p>Designer</p>
              </div>
              <div className="carrer__div13">
                <p>Graphics Designer</p>
              </div>
            </div>
            <div className="carrer__div14">
              <div className="carrer__div15">
                <p>About</p>
              </div>
              <div className="carrer__div16">
                <p>
                As a Graphic Designer you will be involved in designing and safeguarding the brand’s visual identity across multiple mediums including digital advertising, website and social media. We are looking for well-rounded candidate with strong graphic design skills, a background in motion graphics and preferably an Apple fan. <Link to="/graphic-designer/" style={{ color: "#f2d729" }}>Know More</Link>
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      {/* </Link> */}
      {/* <Link to="/videoeditor" style={{ textDecoration: "none", color: "#fff" }}> */}
        <div className="arrer__extra10" style={{display: "none"}}>
          <div className="carrer__div9"></div>
          <div className="carrer__div10">
            <div className="carrer__div11">
              <div className="carrer__div12">
                <p>Editor</p>
              </div>
              <div className="carrer__div13">
                <p>Video Editor</p>
              </div>
            </div>
            <div className="carrer__div14">
              <div className="carrer__div15">
                <p>About</p>
              </div>
              <div className="carrer__div16">
                <p>
                We are hiring for a skilled video editor with tech knowledge. Interested candidates should have an understanding of shooting product videos, podcast episodes, reels and more. Brownie points if you have experience working for a tech channel before. <Link to="/video-editor/" style={{ color: "#f2d729" }}>Know More</Link>
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      {/* </Link> */}
      <div className="arrer__extra10">
          <div className="carrer__div9"></div>
          <div className="carrer__div10">
            <div className="carrer__div11">
              <div className="carrer__div12">
                <p>Creator</p>
              </div>
              <div className="carrer__div13">
                <p>Content Creator</p>
              </div>
            </div>
            <div className="carrer__div14">
              <div className="carrer__div15">
                <p>About</p>
              </div>
              <div className="carrer__div16">
                <p>
                iGeeks is looking for a content creator & Apple fan.Someone who can work with teams handling different projects, across the organization, to write engaging content for humans and not just search engines. The ask is to write content that enhances user experience across Apple's ecosystem whilst keeping it engaging and easy to understand. <Link to="/content-creator/" style={{ color: "#f2d729" }}>Know More</Link>
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      <div className="arrer__extra10">
          <div className="carrer__div9"></div>
          <div className="carrer__div10">
            <div className="carrer__div11">
              <div className="carrer__div12">
                <p>Writer</p>
              </div>
              <div className="carrer__div13">
                <p>Copywriter</p>
              </div>
            </div>
            <div className="carrer__div14">
              <div className="carrer__div15">
                <p>About</p>
              </div>
              <div className="carrer__div16">
                <p>
                We are looking for a creative Copywriter to write clear and concise copy for ads, publications and websites. Your words will inform and engage target audiences.
Our ideal candidate is a team-spirited, skilled and imaginative writer with an eye for detail. If you can grasp project requirements quickly and offer valuable insight, we’d like to meet you. <Link to="/copywriter/" style={{ color: "#f2d729" }}>Know More</Link>
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      <div className="carrer__div90"></div>
    </motion.div>
    </HelmetProvider>
  );
}

export default Carrer;

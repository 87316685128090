import React from 'react'
import "../../styles/HomeThreeBoxes.css"

function HomeThreeBoxes() {
  return (
    <div className='HomeThreeBoxes'>
      <div data-aos="fade-up" data-aos-delay="300" className="HomeBox">
        <h2>Monthly Users</h2>
        {/* <hr /> */}
        <div className="boxNumber num1">
        1,524,405+
        </div>
      </div>
      <div data-aos="fade-up" data-aos-delay="550" className="HomeBox">
        <h2>Monthly Page Views</h2>
        {/* <hr /> */}
        <div className="boxNumber num2">
        4,124,676+
        </div>
      </div>
      <div data-aos="fade-up" data-aos-delay="600" className="HomeBox">
        <h2>Total Page Views</h2>
        {/* <hr /> */}
        <div className="boxNumber num3">
        321,134,674+
        </div>
      </div>
    </div>
  )
}

export default HomeThreeBoxes
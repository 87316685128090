import React from "react";
import daily from "../../images-webp/dailymail.webp";
import digitalTrends from "../../images-webp/digitaltrends.webp";
import express from "../../images-webp/express.webp";
import firstProst from "../../images-webp/firstpost.webp";
import forbes from "../../images-webp/forbes.webp";
import usaToday from "../../images-webp/usatoday.webp";
import interNew1 from "../../images-webp/internew1.webp";
import interNew2 from "../../images-webp/internew2.webp";
import Marquee from "react-fast-marquee";

function HomeInternationalAccol() {
  return (
    <div className="home__div61">
      <div className="home__div38">
        <div className="home__div39">
          <div className="home__div40">
            <div className="home__div41"></div>
            <div className="home__div42">
              <p>International</p>
              <p>Accolades</p>
            </div>
          </div>
          <div className="home__div43">
          </div>
        </div>
      </div>
      <div className="home__div62">
        <div className="home__div62Extra">
          <Marquee speed={100} gradient={false}>
            <div className="home__div63">
              <a href="https://www.digitaltrends.com/mobile/will-apples-2020-ipad-pros-sport-a-triple-camera/" target="_blank" rel="noreferrer">
                <img src={digitalTrends} className="home__brand1" alt="" />
              </a>
            </div>
            <div className="home__div63">
              <a href="https://www.dailymail.co.uk/sciencetech/article-7830523/Renders-claiming-2020-iPad-Pros-reveal-triple-rear-camera.html" target="_blank" rel="noreferrer">
                <img src={daily} className="home__brand1" alt="" />
              </a>
            </div>
            <div className="home__div63">
              <a href="https://www.express.co.uk/life-style/science-technology/1258184/Apple-iPhone-SE-2-Release-Date-Speed-A13-iPhone-11-Pro" target="_blank" rel="noreferrer">
                <img src={express} className="home__brand1" alt="" />
              </a>
            </div>
            <div className="home__div63">
              <a href="https://www.firstpost.com/tech/news-analysis/early-renders-of-apple-2020-ipad-pro-11-and-12-9-show-triple-rear-cameras-7834481.html" target="_blank" rel="noreferrer">
                <img src={firstProst} className="home__brand1" alt="" />
              </a>
            </div>
            <div className="home__div63">
              <a href="https://www.forbes.com/sites/hvmacarthur/2020/01/23/how-to-turbo-boost-your-job-search/" target="_blank" rel="noreferrer">
                <img src={forbes} className="home__brand1" alt="" />
              </a>
            </div>
            <div className="home__div63">
              <a href="https://www.usatoday.com/story/tech/columnist/2018/09/07/apple-iphone-rumors/1222134002/" target="_blank" rel="noreferrer">
                <img src={usaToday} className="home__brand1" alt="" />
              </a>
            </div>
            <div className="home__div63">
              <a href="https://www.theverge.com/2020/3/26/21195464/oneplus-8-pro-leak-green-color-wireless-charging-5g-120-hz" target="_blank" rel="noreferrer">
                <img src={interNew1} className="home__brand1" alt="" />
              </a>
            </div>
            <div className="home__div63">
              <a href="https://www.cnet.com/tech/mobile/oneplus-8-8-pro-leak-details-what-to-expect-in-the-companys-next-flagships/" target="_blank" rel="noreferrer">
                <img
                  style={{ marginLeft: "4vw" }}
                  src={interNew2}
                  className="home__brand1"
                  alt=""
                />
              </a>
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
}

export default HomeInternationalAccol;

export function onMouseEnetrs() {
  document.getElementById("home__div47").style.color = "#fff";
  document.getElementById("home__div46").style.transform = "rotateX(-13deg)";
  document.getElementById("home__div46").style.position = "relative";
  document.getElementById("home__div46").style.top = "-11vw";
}

export function onMouseLeave() {
  document.getElementById("home__div47").style.color = "#f2d729";
  document.getElementById("home__div46").style.transform = "rotateX(-23deg)";
  document.getElementById("home__div46").style.position = "relative";
  document.getElementById("home__div46").style.top = "0vw";
}

export function onMouseEnetrss() {
  document.getElementById("home__div470").style.color = "#fff";
  document.getElementById("home__div460").style.transform = "rotateX(-13deg)";
  document.getElementById("home__div460").style.position = "relative";
  document.getElementById("home__div460").style.top = "-11vw";
}

export function onMouseLeaves() {
  document.getElementById("home__div470").style.color = "#f2d729";
  document.getElementById("home__div460").style.transform = "rotateX(-23deg)";
  document.getElementById("home__div460").style.position = "relative";
  document.getElementById("home__div460").style.top = "0vw";
}

export function onMouseEnetrsss() {
  document.getElementById("home__div4700").style.color = "#fff";
  document.getElementById("home__div4600").style.transform = "rotateX(-13deg)";
  document.getElementById("home__div4600").style.position = "relative";
  document.getElementById("home__div4600").style.top = "-11vw";
}

export function onMouseLeavess() {
  document.getElementById("home__div4700").style.color = "#f2d729";
  document.getElementById("home__div4600").style.transform = "rotateX(-23deg)";
  document.getElementById("home__div4600").style.position = "relative";
  document.getElementById("home__div4600").style.top = "0vw";
}

import "../../animations/ourStoryAnimation";
import {
  FifthSvg,
  FirstLine,
  FirstSvg,
  FourthLine,
  FourthSvg,
  SecondLine,
  SecondSvg,
  ThirdLine,
  ThirdSvg,
} from "../../svg/OurStorySvg";

function OurStory() {
  return (
    <>
      <h1>OUR STORY</h1>

      {/* SVG 1 */}
      <FirstSvg />

      <div className="svgText1" data-aos="fade-up">
        In 2012, iGeeksBlog began as a way for like-minded people to interact.
        Two IT enthusiasts juggled 9-5 jobs and founded iGeeksBlog as a side
        hustle.
      </div>

      {/* Line Svg 1 */}

      <FirstLine />

      {/* SVG 2 */}

      <SecondSvg />

      <div className="svgText2" data-aos="fade-up">
        Over the next three years, iGeeksBlog expanded to start its own blogs
        on - all things Apple. According to statistics, over 1 million people
        read our content on a monthly basis.
      </div>

      {/* Line 2 SVG  */}

      <SecondLine />

      {/* SVG 3  */}

      <ThirdSvg />

      <div className="svgText3" data-aos="fade-up">
        Over a short period of time iGeeksBlog grew and helped over 345 million
        people get the most out of their Apple products. This was the time it
        started its own Youtude Podcasts and started gaining huge popularity.
      </div>

      {/* Line Svg 3 */}

      <ThirdLine />

      {/* SVG 4 */}

      <FourthSvg />

      <div className="svgText4" data-aos="fade-up">
        In 2020, we started our podcasts in Hindi as well and soon expanded our
        digital presence over all social media channels. Today, iGeeks is one of
        the most popular technological information platforms, attracting both
        individual customers and industry watchers.
      </div>

      {/* line svg 4 */}

      <FourthLine />

      {/* SVG5 */}

      <FifthSvg />
    </>
  );
}

export default OurStory;

import React, { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Sphere, MeshDistortMaterial, GradientTexture } from '@react-three/drei';
import * as THREE from "three";
import metal from "../../images/gradient1.jpg";

function Blob() {

  const texture = new THREE.TextureLoader().load(metal);

  const mesh = useRef(null)
  useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.005));

  return (
    <Sphere  ref={mesh} visible args={[1, 100, 200]} scale={1.7} >
      <MeshDistortMaterial
        attach="material"
        map={texture}
        distort={0.9}
        speed={1.5}
        roughness={2}
      >
        {/* <GradientTexture stops={[0.3, 0.8, 1, 0.7,0.5,0.7,0.6,0.5,0.8]} colors={['#971315', '#99046c', '#04909d', '#1a07b0', '#660499', '#4d125b', '#2b522d', '#054fa9', '#1b0982']} size={100} /> */}
      </MeshDistortMaterial>
    </Sphere>
  )
}
// 0062ff

export default Blob;

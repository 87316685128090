import React from "react";

function HomeAboutUsSection() {
  return (
    <div className="home__div29">
      <div className="home__div30"></div>
      <div className="home__div31"></div>
      <div className="home__div32">
        <p>Making Technology simple for Millions.</p>
      </div>
      <div className="home__div31"></div>
      <div className="home__div33">
        <div className="home__div34">
          <p style={{ color: "#f2d729" }}>Our Vision</p>
          <p>
            iGeeks Media dreams to be a pioneer in the world of technology. We
            will remain a leading source of information, with the goal of
            providing captivating content and innovative solutions to enrich
            people's lives by enabling them to learn more, do more, and live
            inspired lives.
          </p>
        </div>
        <div className="home__div35">
          <p style={{ color: "#f2d729" }}>Our Mission</p>
          <p>
            We're on a quest to bring you the most up-to-date, accurate, and
            multi-dimensional tech news and information possible. We leave no
            stone unturned in our search for accurate information from reliable
            sources..
          </p>
        </div>
        <div className="home__div36">
          <p style={{ color: "#f2d729" }}>Our Philosophy</p>
          <p>
            We live by the connected world and connected experiences philosophy.
            We thrive on change that is fueled by technology's intelligent
            symphony. We believe that collective efforts lead to success, and
            the entire team is a part of that accomplishment. Success is within
            shouting distance when talented people with passion and discipline
            join together.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomeAboutUsSection;

import "../../App.css"
import Blob from "./Blob";

import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";

function Kawaii() {
  return (
    <div className="blob__holder">
       <Canvas className="canvas">
         <ambientLight intensity={0.5} />
         <directionalLight position={[-2, 5, 2]} intensity={1} />
         <Suspense fallback={null}>
           <Blob />
         </Suspense>
       </Canvas>
    </div>
  );
}

export default Kawaii;

function Svg1() {
    var svg1 = document.getElementsByClassName("svg1");
  
    for (var i = 0; i < svg1.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = svg1[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        svg1[i].classList.add("svgAnimate1");
      } else {
        svg1[i].classList.remove("svgAnimate1");
      }
    }
  }
  
  window.addEventListener("scroll", Svg1);
  function SvgLine1() {
    var svgLine1 = document.getElementsByClassName("svgLine1");
  
    for (var i = 0; i < svgLine1.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = svgLine1[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        svgLine1[i].classList.add("svgLineAnimate1");
      } else {
        svgLine1[i].classList.remove("svgLineAnimate1");
      }
    }
  }
  
  window.addEventListener("scroll", SvgLine1);
  function Svg2() {
    var svg2 = document.getElementsByClassName("svg2");
  
    for (var i = 0; i < svg2.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = svg2[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        svg2[i].classList.add("svgAnimate2");
      } else {
        svg2[i].classList.remove("svgAnimate2");
      }
    }
  }
  
  window.addEventListener("scroll", Svg2);
  function SvgLine2() {
    var svgLine2 = document.getElementsByClassName("svgLine2");
  
    for (var i = 0; i < svgLine2.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = svgLine2[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        svgLine2[i].classList.add("svgLineAnimate2");
      } else {
        svgLine2[i].classList.remove("svgLineAnimate2");
      }
    }
  }
  
  window.addEventListener("scroll", SvgLine2);
  function Svg3() {
    var svg3 = document.getElementsByClassName("svg3");
  
    for (var i = 0; i < svg3.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = svg3[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        svg3[i].classList.add("svgAnimate3");
      } else {
        svg3[i].classList.remove("svgAnimate3");
      }
    }
  }
  
  window.addEventListener("scroll", Svg3);
  function SvgLine3() {
    var svgLine3 = document.getElementsByClassName("svgLine3");
  
    for (var i = 0; i < svgLine3.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = svgLine3[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        svgLine3[i].classList.add("svgLineAnimate3");
      } else {
        svgLine3[i].classList.remove("svgLineAnimate3");
      }
    }
  }
  
  window.addEventListener("scroll", SvgLine3);
  function Svg4() {
    var svg4 = document.getElementsByClassName("svg4");
  
    for (var i = 0; i < svg4.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = svg4[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        svg4[i].classList.add("svgAnimate4");
      } else {
        svg4[i].classList.remove("svgAnimate4");
      }
    }
  }
  
  window.addEventListener("scroll", Svg4);
  function SvgLine4() {
    var svgLine4 = document.getElementsByClassName("svgLine4");
  
    for (var i = 0; i < svgLine4.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = svgLine4[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        svgLine4[i].classList.add("svgLineAnimate4");
      } else {
        svgLine4[i].classList.remove("svgLineAnimate4");
      }
    }
  }
  
  window.addEventListener("scroll", SvgLine4);
  function Svg5() {
    var svg5 = document.getElementsByClassName("svg5");
  
    for (var i = 0; i < svg5.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = svg5[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        svg5[i].classList.add("svgAnimate5");
      } else {
        svg5[i].classList.remove("svgAnimate5");
      }
    }
  }
  
  window.addEventListener("scroll", Svg5);
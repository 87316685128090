import React from "react";
import Kawaii from "../components/blob/Kawaii";
// React Helmet
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Seo() {
  return (
    <HelmetProvider>
    <div className="carrer__div1">
      <Helmet>
      {/* <!-- Primary Meta Tags --> */}
<title>Hiring Now for Sr. SEO | SEO Analyst | SEO Executives</title>
<meta name="title" content="Hiring Now for Sr. SEO | SEO Analyst | SEO Executives" />
<meta name="description" content="iGeeks Media is hiring SEO Profiles for the in-house
team. A minimum experience of 1-2 years is required. If you are eager
to learn in-depth SEO, apply now! " />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.igeeksmedia.com/search-engine-optimization/" />
<meta property="og:title" content="Hiring Now for Sr. SEO | SEO Analyst | SEO Executives" />
<meta property="og:description" content="iGeeks Media is hiring SEO Profiles for the in-house
team. A minimum experience of 1-2 years is required. If you are eager
to learn in-depth SEO, apply now!" />
<meta property="og:image" content="" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.igeeksmedia.com/search-engine-optimization/" />
<meta property="twitter:title" content="Hiring Now for Sr. SEO | SEO Analyst | SEO Executives" />
<meta property="twitter:description" content="iGeeks Media is hiring SEO Profiles for the in-house
team. A minimum experience of 1-2 years is required. If you are eager
to learn in-depth SEO, apply now!" />
<meta property="twitter:image" content="" />
      </Helmet>
      <div className="carrer__div2">
        <div className="home__div4">
          <div className="home__div000">
            <Kawaii />
          </div>
          <div className="home__div5"></div>
          <div className="carrer__div8000 title_line">
            <p>
              <span>SEO </span>
              <span>Executive</span>
            </p>
          </div>
        </div>
      </div>
      <div className="staff__div1">
        <div className="arrer__extra1">
          <div className="carrer__div9"></div>
          <div className="carrer__div10">
            <div className="carrer__div11">
              <div className="carrer__div12">
                <p>Writing</p>
              </div>
              <div className="carrer__div13">
                <p>SEO Executive</p>
              </div>
            </div>
            <div className="carrer__div14">
              <div className="carrer__div15">
                <p>About</p>
              </div>
              <div className="carrer__div16">
                <p>
                We are looking for an SEO Executive to join our Marketing team and help us build a strong online presence. To be successful as an SEO Executive, you should be passionate about technology and digital communications.
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="staff__div2">
        <div className="staff__div3">
          <p>Interested?</p>
          <div className="apply_Button">
            <a href="mailto:hr@igeeksmedia.com" target="_blank" rel="noreferrer">Apply Now</a>
          </div>
        </div>
        <div className="staff__div4">
          <p>Requirements:</p>
          <div className="staff__div5">
            <div className="staff__div6">
              <p>Key Responsibilities:</p>
            </div>
            <div className="staff__div7">
              <ul>
                <li>
                Conduct keyword research and identify target keywords
                </li>
                <li>
                Optimize website content for search engines
                </li>
                <li>
                Build backlinks to improve website rankings
                </li>
                <li>
                Work with the marketing team to develop and execute SEO-related marketing campaigns
                </li>
                <li>Monitor website traffic and rankings</li>
                <li>
                Stay up-to-date on the latest SEO trends
                </li>
              </ul>
            </div>
          </div>
          <div className="staff__div50">
            <div className="staff__div6">
              <p>Qualifications:</p>
            </div>
            <div className="staff__div7">
              <ul>
                <li>
                Bachelor's degree in marketing, communications, or a related field
                </li>
                <li>
                3+ years of experience in SEO
                </li>
                <li>
                Strong understanding of SEO principles and best practices
                </li>
                <li>
                Excellent analytical and problem-solving skills
                </li>
                <li>
                Excellent written and verbal communication skills
                </li>
                <li>Ability to work independently and as part of a team</li>
              </ul>
            </div>
          </div>
          <div className="staff__div50">
            <div className="staff__div6">
              <p>Skills:</p>
            </div>
            <div className="staff__div7">
              <ul>
                <li>Keyword research</li>
                <li>Content optimization</li>
                <li>Backlink building</li>
                <li>SEO marketing campaigns</li>
                <li>Website analytics</li>
                <li>SEO software</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    </HelmetProvider>
  );
}

export default Seo;

import React from "react";

function HomeApprochSection() {
  return (
    <div className="home__div0">
      <div className="home__div12">
        <div className="home__div13">
          <p>
            Approch
            <span>
              &nbsp;
              &#8594;
            </span>
          </p>
        </div>
      </div>
      <div className="home__div14">
        <p>
          <span>iGeeks Media </span>is a technology-driven portal that serves as
          the go-to resource for all tech-related things. We act as a reliable
          guide through the flood of information and ideas that surround people.
          Our team of skilled tech enthusiasts provides a perspective that is
          representative of a changing dynamic through insightful analysis and
          honest viewpoints. We excel through our posts that strive to be
          interesting, entertaining and complemented with high-quality media.
        </p>
      </div>
    </div>
  );
}

export default HomeApprochSection;

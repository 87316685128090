import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import member1 from "../../images-webp/members/Dhvanesh Adhiya.webp";
import member2 from "../../images-webp/members/Jignesh Padhiyar.webp";
// import LinkedInIcon from '@mui/icons-material/LinkedIn';

function HomeOurFoundation({ inView, ourTeam }) {
  useEffect(() => {
    if (inView) {
      // document.body.style.backgroundColor = "#f2d729";
      // document.getElementById("team_redirect").style.color = "#fff";
      document.getElementById("team_redirect").style.color = "#222";
      document.getElementById("team_link").style.color = "#222";
      // document.getElementById("ham__svg1").style.stroke = "#222";
    } else {
      // document.body.style.backgroundColor = "#222";
      // document.getElementById("team_redirect").style.color = "#f2d729";
      document.getElementById("team_redirect").style.color = "#fff";
      document.getElementById("team_link").style.color = "#fff";
      // document.getElementById("ham__svg1").style.stroke = "#f2d729";
    }
  }, [inView]);
  return (
    <div className="home__div52">
      <div className="home__div38">
        <div className="home__div39">
          <div className="home__div40">
            <div className="home__div41"></div>
            <div className="home__div42">
              <p>Meet Our</p>
              <p>Foundation</p>
            </div>
          </div>
          <div className="home__div43"></div>
        </div>
      </div>
      <div className="home__div55">
        <div className="home__div55Extra">
          <div className="home__div56">
            <div data-aos="fade-up" className="home__div57">
            <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/dhvanesh/">
              <div className="founder">
                <img src={member1} className="home__img40"  alt="" />
              </div>
              </a>
              <div className="home__extra67">
                <p style={{ color: "#f2d729" }}>Dhvanesh Adhiya</p>
                <p>Founder</p>
                {/* <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/dhvanesh/"><p>LinkedIn</p></a> */}
              </div>
            </div>
          </div>
          <div className="home__div59">
            <p>
              As an IT professional, he spent almost 5 years exploring the
              complex realms of Internet Marketing before starting iGeeksBlog.
            </p>
            <p>
              After writing hundreds of articles spanning across editorials,
              reviews, how-tos, and buying guides for iGeeksBlog, he now wears
              the cap of editor-in-chief. He ensures every content addresses the
              issues readers face and meets the quality standards, and mentors
              the team.
            </p>
          </div>
        </div>
        <div className="home__div55Extra">
          <div className="home__div59 ourFound2">
            <p>
              After growing iGeeksBlog to an army of 21+ nerds alongside
              Dhvanesh, he had retired from writing. Now, he solely focuses on
              building business relationships, monetization strategies, and
              expanding iGeeks Media Private Limited, capitalizing on his 14
              years of vast experience in digital marketing.
            </p>

            <p>
              Now you know whom to connect with if you are looking to advertise
              or associate with us!
            </p>
          </div>
          <div className="home__div56">
            <div data-aos="fade-up" className="home__div57">
            <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/padhiyarjignesh/">
              <div className="founder">
              <img src={member2} className="home__img40" alt="" />
              </div>
            </a>
              <div className="home__extra67">
                <p style={{ color: "#f2d729" }}>Jignesh Padhiyar</p>
                <p>Co-founder</p>
                {/* <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/padhiyarjignesh/"><p>LinkedIn</p></a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="home__div55Extra">
          <div className="our_team">
            <h1 id="team_redirect">
              Say "HELLO 👋" to &nbsp; <Link to="/our-team/" onClick={()=>{ setTimeout( ()=>{window.location.reload()}, 100) }}> <span onClick={() => ourTeam()} style={{textDecoration: "underline", cursor: "pointer"}} id="team_link">OUR TEAM</span></Link>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeOurFoundation;

import React from "react";

function HomeIgeeksBlogsection() {
  return (
    <div className="home__div20Extra">
      <div className="home__div21">
        <p>
          iGeeks Media takes centre stage in the tech industry by delivering
          breaking information in a digestible format. We provide technology
          news, tutorials, opinions, live event coverage, and more to several
          million visitors including tech experts, engineers, and business
          leaders from around the world. Our breakthroughs power new ways of
          thinking, new connections, and smart decisions.
        </p>
      </div>
    </div>
  );
}

export default HomeIgeeksBlogsection;

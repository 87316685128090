import React, { useLayoutEffect } from "react";
import Kawaii from "../components/blob/Kawaii";
import { motion } from "framer-motion";
// React Helmet
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Marketer() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <HelmetProvider>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: "0.5s all ease" }}
      exit={{ opacity: 0 }}
      className="carrer__div1"
    >
      <Helmet>
      {/* <!-- Primary Meta Tags --> */}
<title>Content Marketer - iGeeks Media</title>
<meta name="title" content="Content Marketer - iGeeks Media" />
<meta name="description" content="iGeeks Media is hiring an experienced content marketer.  You’ll be responsible for all content marketing initiatives along with crafting the best blogs for our audience. " />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.igeeksmedia.com/content-marketer/" />
<meta property="og:title" content="Content Marketer - iGeeks Media" />
<meta property="og:description" content="iGeeks Media is hiring an experienced content marketer.  You’ll be responsible for all content marketing initiatives along with crafting the best blogs for our audience. " />
<meta property="og:image" content="" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.igeeksmedia.com/content-marketer/" />
<meta property="twitter:title" content="Content Marketer - iGeeks Media" />
<meta property="twitter:description" content="iGeeks Media is hiring an experienced content marketer.  You’ll be responsible for all content marketing initiatives along with crafting the best blogs for our audience. " />
<meta property="twitter:image" content="" />
      </Helmet>
      <div className="carrer__div2">
        <div className="home__div4">
          <div className="home__div000">
            <Kawaii />
          </div>
          <div className="home__div5"></div>
          <div className="carrer__div8000 title_line">
            <p>
              <span>Content </span>
              <span>Marketer</span>
            </p>
          </div>
        </div>
      </div>
      <div className="staff__div1">
        <div className="arrer__extra1">
          <div className="carrer__div9"></div>
          <div className="carrer__div10">
            <div className="carrer__div11">
              <div className="carrer__div12">
                <p>Writing</p>
              </div>
              <div className="carrer__div13">
                <p>Content Marketer</p>
              </div>
            </div>
            <div className="carrer__div14">
              <div className="carrer__div15">
                <p>About</p>
              </div>
              <div className="carrer__div16">
                <p>
                  Igeeksmedia is looking for a full-time Marketing Director. In
                  this role, you will drive marketing strategy for Igeeksmedia
                  and a portfolio of clients while building and developing a
                  growing marketing team.
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="staff__div2">
        <div className="staff__div3">
          <p>Interested?</p>
          <div className="apply_Button">
            <a href="mailto:hr@igeeksmedia.com" target="_blank" rel="noreferrer">Apply Now</a>
          </div>
        </div>
        <div className="staff__div4">
          <p>Requirements:</p>
          <div className="staff__div5">
            <div className="staff__div6">
              <p>Key Responsibilities:</p>
            </div>
            <div className="staff__div7">
              <ul>
                <li>Implement outbound marketing strategy.</li>
                <li>
                  Promote content using Web 2.0 like medium, Quora, LinkedIn,
                  Forums, Reddit, etc.
                </li>
                <li>
                  The content should be grammatically correct and with an easy
                  to understand language.
                </li>
                <li>Build industry specific network.</li>
                <li>
                  Create, implement, and measure the email marketing campaign
                </li>
              </ul>
            </div>
          </div>
          <div className="staff__div50">
            <div className="staff__div6">
              <p>Skills set to get succeed:</p>
            </div>
            <div className="staff__div7">
              <ul>
                <li>
                  Should have at least 3+ years of work experience as a content
                  marketer or similar role. Working with online publication or
                  media will be considered as an added advantage.
                </li>
                <li>Must have great command over English.</li>
                <li>Content writing and editing skill.</li>
                <li>Must have Copywriting skill.</li>
                <li>
                  It would be great if you have knowledge about email campaigns
                  and paid advertising.
                </li>
                <li>Should have great communication skill.</li>
              </ul>
            </div>
          </div>
          {/* <div className="staff__div50">
            <div className="staff__div6">
              <p>What will you get in return?</p>
            </div>
            <div className="staff__div7">
              <ul>
                <li>Flexible Timing</li>
                <li>Family Mediclaim & Accident Policy</li>
                <li>
                  A share in our success - Get a chance to receive a profit pool
                  bonus at the end of our financial year
                </li>
                <li>
                  Healthy snacks, fruits, beverages and lunch on every working
                  Saturday.
                </li>
                <li>
                  Fitness facilities (gym, yoga membership) at discount price
                </li>
                <li>
                  Free digital magazine subscriptions of Magzter/Bookazines
                </li>
                <li>
                  While working you can refresh yourself by playing Foosball,
                  tabled basketball, or compete with other on Xbox/Play Station.
                </li>
                <li>Library access</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </motion.div>
    </HelmetProvider>
  );
}

export default Marketer;

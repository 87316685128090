import React, { useLayoutEffect } from "react";
import Kawaii from "../components/blob/Kawaii";
import { motion } from "framer-motion";
// React Helmet
import { Helmet, HelmetProvider } from 'react-helmet-async';

function SocialMedia() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <HelmetProvider>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: "0.5s all ease" }}
      exit={{ opacity: 0 }}
      className="carrer__div1"
    >
      <Helmet>
      {/* <!-- Primary Meta Tags --> */}
<title>Social Media Strategist - iGeeks Media</title>
<meta name="title" content="Social Media Strategist - iGeeks Media" />
<meta name="description" content="Do you love scrolling on social media and can come up with unbeatable strategies? We're looking for you. Head over to the careers page and apply now" />

{/* <!-- Open Graph / Facebook --> */}
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.igeeksmedia.com/social-media/" />
<meta property="og:title" content="Social Media Strategist - iGeeks Media" />
<meta property="og:description" content="Do you love scrolling on social media and can come up with unbeatable strategies? We're looking for you. Head over to the careers page and apply now" />
<meta property="og:image" content="" />

{/* <!-- Twitter --> */}
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.igeeksmedia.com/social-media/" />
<meta property="twitter:title" content="Social Media Strategist - iGeeks Media" />
<meta property="twitter:description" content="Do you love scrolling on social media and can come up with unbeatable strategies? We're looking for you. Head over to the careers page and apply now" />
<meta property="twitter:image" content="" />
      </Helmet>
      <div className="carrer__div2">
        <div className="home__div4">
          <div className="home__div000">
            <Kawaii />
          </div>
          <div className="home__div5"></div>
          <div className="carrer__div8000 title_line">
            <p>
              <span>Social Media </span>
              <span>Strategist</span>
            </p>
          </div>
        </div>
      </div>
      <div className="staff__div1">
        <div className="arrer__extra1">
          <div className="carrer__div9"></div>
          <div className="carrer__div10">
            <div className="carrer__div11">
              <div className="carrer__div12">
                <p>Writing</p>
              </div>
              <div className="carrer__div13">
                <p>Social Media Strategist</p>
              </div>
            </div>
            <div className="carrer__div14">
              <div className="carrer__div15">
                <p>About</p>
              </div>
              <div className="carrer__div16">
                <p>
                To meet our business growth, we are looking for a talented Social Media Manager to administer our social media accounts. The role holder will be responsible for planning original text and video content, managing posts etc. Further, the candidate will handle social media presence ensuring high levels of web traffic and customer engagement while managing company and client image in a cohesive way to achieve the defined marketing goals.
                </p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="staff__div2">
        <div className="staff__div3">
          <p>Interested?</p>
          <div className="apply_Button">
            <a href="mailto:hr@igeeksmedia.com" target="_blank" rel="noreferrer">Apply Now</a>
          </div>
        </div>
        <div className="staff__div4">
          <p>Requirements:</p>
          <div className="staff__div5">
            <div className="staff__div6">
              <p>Key Responsibilities:</p>
            </div>
            <div className="staff__div7">
              <ul>
                <li>
                  Keep an eye on latest development in Social Media trends and
                  consumer-tech as well.
                </li>
                <li>
                  Plan and execute paid campaigns in order to achieve maximum
                  ROI.
                </li>
                <li>Conceptualize and execute social media strategies.</li>
                <li>
                  Track reports, analyse the insights and based on that improve
                  the Performance of the campaign.
                </li>
                <li>Engage with Audience in real-time.</li>
                <li>Create and schedule editorial calendar.</li>
                <li>
                  Establish connections with the content creators and
                  influencers.
                </li>
                <li>
                  Monitor market trends, research consumer markets, and
                  competitor activities to increase visibility of social
                  contents.
                </li>
              </ul>
            </div>
          </div>
          <div className="staff__div50">
            <div className="staff__div6">
              <p>Skills set to get succeed:</p>
            </div>
            <div className="staff__div7">
              <ul>
                <li>
                  3+ Years of proven SMM experience is must. Working with online
                  publication or media will be considered as an added advantage.
                </li>
                <li>
                  Creative mind who can visualise the campaign to craft in a
                  form of design.
                </li>
                <li>Must have good command over English.</li>
                <li>
                  Exceptional working knowledge on Instagram, Twitter, Facebook,
                  YouTube, LinkedIn, etc.
                </li>
                <li>Must have copywriting skill.</li>
                <li>
                  Post Graduate, Graduate or holding Diploma in Mass
                  Media/Marketing Communication/Advertising or equivalent.
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="staff__div50">
            <div className="staff__div6">
              <p>What will you get in return?</p>
            </div>
            <div className="staff__div7">
              <ul>
                <li>Flexible Timing</li>
                <li>Family Mediclaim & Accident Policy</li>
                <li>
                  A share in our success - Get a chance to receive a profit pool
                  bonus at the end of our financial year
                </li>
                <li>
                  Healthy snacks, fruits, beverages and lunch on every working
                  Saturday.
                </li>
                <li>
                  Fitness facilities (gym, yoga membership) at discount price
                </li>
                <li>
                  Free digital magazine subscriptions of Magzter/Bookazines
                </li>
                <li>
                  While working you can refresh yourself by playing Foosball,
                  tabled basketball, or compete with other on Xbox/Play Station.
                </li>
                <li>Library access</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </motion.div>
    </HelmetProvider>
  );
}

export default SocialMedia;
